/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block;
  vertical-align: middle;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @extend .rounded;

  &--rounded {
    @extend .rounded;
  }

  font: inherit;
  text-align: center;
  margin: 0;
  cursor: pointer;
  border: 0;
  padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit;
  @include inuit-font-size(14px, 24px);

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &--full {
    width: 100%;
    box-sizing: border-box;
  }

  &--submit {
    &:focus {
      background: url('/img/icons/loading.gif') $color-alto no-repeat 4px center;
      background-size: 14px;
    }
  }

  .arrow {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;

    &.left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }
}

/*
 * Add focus style on form state buttons
 */
.c-form--state button[type='submit'] {
  &.c-btn--minsk:focus {
    background: $color-minsk-hover;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  &.c-btn--curious:focus {
    background: $color-curious-hover;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}

/* Size variants
   ========================================================================== */

.c-btn--tiny {
  padding: 3px 10px;

  .icon {
    width: 18px;
    height: 18px;
    background-size: 14px;

    &--trash {
      background-size: 12px;
    }

    &--preview {
      background-size: 19px;
    }
  }
}

.c-btn--tiniest {
  padding: 1px 5px;

  .icon {
    width: 18px;
    height: 18px;
    background-size: 14px;

    &--trash {
      background-size: 12px;
    }

    &--preview {
      background-size: 19px;
    }
  }
}

.c-btn--small {
  padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
}

.c-btn--large {
  padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
}

.c-btn-w--large {
  width: 274px;
}

.c-btn--long {
  padding-left: 32px;
  padding-right: 32px;
}

/* Style variants
   ========================================================================== */

.c-btn--minsk {
  background-color: $color-minsk;

  &,
  &:hover,
  &:active {
    color: #fff;
  }

  &:hover {
    background-color: $color-minsk-hover;
  }
}

.c-btn--curious {
  background-color: $color-curious;

  &,
  &:hover,
  &:active {
    color: #fff;
  }

  &:hover {
    background-color: $color-curious-hover;
  }

  &-active {
    background-color: $color-curious-hover;
  }
}

.c-btn--transparent {
  background-color: transparent;
  color: $color-tuna;
  border: 1px solid $color-alto;

  &-curious {
    color: $color-curious;
    border-color: $color-curious;
  }

  &:hover,
  &:active,
  &.active {
    color: $color-curious;
  }

  &:hover {
    background-color: transparent;
  }
}

.u-flag--isPayfile {
  .c-btn {
    &[disabled] {
      background: $color-alto no-repeat 4px center;
      background-size: 14px;
    }
  }
}

/*
 * Show loader on submitting/downloading, while btn is disabled
 */
.c-btn {
  &--submitting[disabled],
  &--downloading[disabled] {
    background: url('/img/icons/loading.gif') $color-alto no-repeat 4px center !important;
    background-size: 14px !important;
  }
}

.c-fab-button {
  position: fixed;
  right: 10px;
  z-index: 99999;
}

@media only screen and (max-width: 1390px) {
  .c-fab-button {
    right: 10px;
  }
}

.dropdown-button-drawer {
  position: absolute;
  list-style: none;
  z-index: 9999;
  right: 0;
  animation: fadeIn 200ms ease-in;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  li {
    background-color: $color-white;
    padding: 10px 25px;
    min-width: 100px;
    z-index: 9999;
    cursor: pointer;

    &:hover {
      background-color: $color-light-purple;
    }
  }
}

.dropdown-icon-container {
  display: inline-block;
  position: relative;
}

.dropdown-icon-button {
  display: flex;
  flex-direction: column;
  position: absolute;
  list-style: none;
  z-index: 9999;
  animation: fadeIn 200ms ease-in;
  box-shadow: 0 0 7px 0 #0000001A;
  border-radius: 5px;
  border: 1px solid #DBD6E2;
  background-color: $color-white;
  top: 100%;

  &--top-aligned {
    top: 28px !important;
  }

  &--right-aligned {
    right: -8px !important;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  span {
    width: 84px;
    height: 37px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $color-light-purple;
    }
  }
}

.c-btn--unset-default-styles {
  all: unset;
}