/* ==========================================================================
   #Switch toggle
   ========================================================================== */

.react-switch {
  padding: 3px;
  opacity: 1 !important;
  &-handle {
    top: 4px !important; //if not using important, the scripts override the styles and break the layout
  }
}