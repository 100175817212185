/* ==========================================================================
   #HEADER ADMIN
   ========================================================================== */

/**
 * Default styling for header section.
 */

.c-header {
  background: linear-gradient(90deg, #F5F3F8 15%, #E8EDFA 100%);
  padding: 21px $inuit-global-spacing-unit-large;
  position: relative;

  // This one covers the blank space caused by the rounded corners of the side menu
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin-left: -100%;
    background: $color-whisper;
  }
}

.employee-header {
  &-details {
    span {
      line-height: 20px;
    }

    span:nth-of-type(3) {
      line-height: 17px;
    }
  }
}

.c-header-subbranding {
  display: none;
  color: $ps-heading-subbranding-color
}
