/* ==========================================================================
   #INDICATORS
   ========================================================================== */

.c-indicator {
  // Make `.c-indicator` compatible with other utilities, these are changing the `line-height`.
  // We make sure that nothing will change the `line-height`, because the indicator text won't be centered
  // vertically correctly. For example `u-text--tiny` changes the `line-height`.
  line-height: 16px!important;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  background-color: $color-cinnabar;
  color: $color-white;
  @extend .u-text--tiny
}
