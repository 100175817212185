@use 'sass:map';

$pieChartAreas: local, expat, contractor, employer-of-record, active, inactive, short-term-assignment, short-term-assignment-host, supplier, non-employee, not-assigned;
$pieChartMapped: (
  local: 'Local Employee',
  expat: 'Expat',
  contractor: 'Contractor',
  employer-of-record: 'Employer of Record',
  active: 'Active',
  inactive: 'Inactive',
  short-term-assignment: 'Short Term Assignment',
  short-term-assignment-host: 'Short Term Assignment (Host)',
  supplier: 'Supplier',
  non-employee: 'Non-employee',
  not-assigned: 'Not Assigned',
);

.page {
  &--reporting-analytics-view {
    tspan {
      fill: $color-black;
    }
    .zero-results-returned {
      top: 40%;
      left: 50%;
      margin-left: -100px;
    }
    .headcount-by-period {
      .recharts-legend-wrapper {
        margin-left: 50px;
      }
    }

    .basic-pie-chart {
      @each $area in $pieChartAreas {
        .label-value-display-#{$area} {
          opacity: 0;
        }
      }

      .recharts-layer.recharts-pie-labels {
        g.recharts-layer {
          @each $area in $pieChartAreas {
            path[name='#{map.get($pieChartMapped, $area)}'] {
              opacity: 0;
            }
          }
        }
      }
      @each $area in $pieChartAreas {
        $name: map.get($pieChartMapped, $area);
        &.active-hover-field-#{$area},
        &.is-exporting {
          .label-value-display-#{$area} {
            opacity: 1;
          }
          .recharts-layer.recharts-pie-labels {
            g.recharts-layer {
              path[name='#{$name}'] {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .headcount-sort {
      top: 1.5rem;
      right: 1.5rem;
    }

    .recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis {
      .recharts-layer.recharts-cartesian-axis-tick {
        font-size: 14px;
      }
    }

    .recharts-label {
      &.recharts-label-header {
        transform: translateY(-20px);
        font-weight: 700;
      }
      &.recharts-label-subheader {
        font-size: 15px;
        transform: translateY(20px);
        font-weight: 400;
      }
    }
    .recharts-layer.recharts-pie-labels {
      .recharts-text.recharts-pie-label-text {
        fill: $color-valhalla;
      }
    }

    .recharts-wrapper {
      svg.recharts-surface {
        .recharts-layer.recharts-pie {
          .recharts-layer.recharts-pie-labels {
            color: $color-valhalla;
          }
        }
      }
    }
  }
  &--reporting-analytics-header {
    .analytics-show-filter {
      max-height: 0;
      padding: 0;
      overflow: hidden;
      @extend .ease-in-out-max-height-250;
      &.filter-opened {
        max-height: 1000px;
        transition: max-height 250ms ease-in-out;
      }
    }
  }
}
