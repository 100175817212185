/* ==========================================================================
   #SLIDER
   ========================================================================== */
.c-slider {
  .slick-track {
    margin: 0;
  }
  &__btn {
    position: absolute;
    top: 50%;
    width: 43px;
    height: 32px;
    background: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border: 0;
    z-index: 20;
    transform: translateY(-50%);
    cursor: pointer;

    &--prev {
      right: $inuit-global-spacing-unit-large - 2;
    }

    &--next {
      right: 0;
    }

    &--prev,
    &--next {
      &:hover {
        path {
          fill: $color-curious;
        }
      }
    }

    &__arrow {
      &--prev {
        transform: rotate(-180deg);
      }
    }
  }
}
