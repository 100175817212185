/* ==========================================================================
   #NAVIGATION
   ========================================================================== */

/**
 * Default styling for main menu navigation.
 */

.c-nav {
  margin-left: 0;
  &--margin-small {
    margin: 0 $inuit-global-spacing-unit-small;
  }
  li {
    position: relative;
    > .o-block  {
      padding: 12px 0 12px 8px;
    }
  }
  .logout {
    position: absolute;
    bottom: $inuit-global-spacing-unit-huge;
    width: 100%;
    @include mq($until: wide) {
      position: relative;
      bottom: 0;
      margin-top: 10px;
    }
    @media (max-height: 780px) {
      position: relative;
      bottom: 0;
      margin-top: 10px;
    }

  }
  > li {
    &:hover{
      .sub-menu {
        display: block;
      }

    }
  }
  .nav-item.has-children.expanded .sub-menu{
    opacity: 1;
    height: auto;
    max-height: 150px;
    overflow-y: auto;
    animation: hide-scroll 600ms backwards;
    &::-webkit-scrollbar {
      width: 14px;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: white;
    }
  }

  @keyframes hide-scroll {
    from, to { overflow: hidden; }
  }

  .nav-item a.active {
    background-color: $color-pantone;
  }

  li.no-children:hover, li.has-children:hover {
    background-color: $color-pantone;
  }

  li {
    transition: all 200ms ease-in-out
  }

  .nav-icon {
    width: 24px;
    height: 24px;
    &--home {
      background: url('/img/icons/home-icon.svg') no-repeat center center;
    }
    &--company {
      background: url('/img/icons/company-icon.svg') no-repeat center center;
    }
    &--people {
      background: url('/img/icons/people-icon.svg') no-repeat center center;
    }
    &--payroll {
      background: url('/img/icons/payroll-icon.svg') no-repeat center center;
    }
    &--payments {
      background: url('/img/icons/payments-icon.svg') no-repeat center center;
    }
    &--toolbox {
      background: url('/img/icons/toolbox-icon.svg') no-repeat center center;
      background-size: 20px;
    }
    &--calendar {
      background: url('/img/icons/calendar-icon.svg') no-repeat center center;
    }
    &--reporting {
      background: url('/img/icons/reporting-icon.svg') no-repeat center center;
    }
    &--clients  {
      background: url('/img/icons/clients-icon.svg') no-repeat center center;
      background-size: 22px;
    }
    &--profile  {
      background: url('/img/icons/profile-icon.svg') no-repeat center center;
      background-size: 20px;
    }
    &--settings {
      background: url('/img/icons/settings-icon.svg') no-repeat right center;
      &-new {
        background: url('/img/icons/settings-icon-new.svg') no-repeat right center;
      }
    }
    &--help {
      background: url('/img/icons/help-icon.svg') no-repeat right center;
    }
    &--logout {
      background: url('/img/icons/logout-icon.svg') no-repeat right center;
      &-new {
        background: url('/img/icons/logout-icon-new.svg') no-repeat right center;
      }
    }
    &--tiny {
      background-size: 13px;
    }
    &--logout-aside {
      margin-left: 3px !important;
      background: url('/img/icons/logout.svg') no-repeat right center;
    }
    &--control-center {
      background: url('/img/icons/integration-center-icon.svg') no-repeat center center;
    }
    &--resources {
      background: url('/img/icons/resources-icon.svg') no-repeat center center;
    }
  }
  .nav-label {
    color: $color-white;
    font-size: 16px;
  }
  .nav-arrow {
    position: absolute;
    top: 16px;
    right: 7px;
    width: 15px;
    height: 15px;
    background: url('/img/icons/arrow-icon-down-aside.svg') no-repeat center center;
    background-size: 10px 10px;
    transition: all 300ms ease-in-out;
  }
  .nav-item.has-children.expanded {
    .nav-arrow {
      transform: rotate(180deg);
    }
  }
  .sub-menu {
    display: block;
    top: 50px;
    width: 190px;
    height: 0;
    max-height: 0;
    opacity: 0;

    overflow-y: hidden;
    transition: all 100ms ease-in-out;
    &--bottom {
      top: inherit;
      bottom: 0;
    }
    > li {
      margin: 0 $inuit-global-spacing-unit;
      .nav-label {
        color: $color-white;
      }
      &:hover {
        .nav-arrow {
          transform: rotate(-90deg);
        }
      }
      &:hover,
      a.active {
        background-color: $color-pantone;
      }
    }
    .menu-link a {
      padding-left: 2rem;
    }
  }

}

/**
 * Default styling for main menu navigation when aside section is collapsed
 */

.o-aside--collapsed {
  .o-aside .nav-icon {
    margin: 0 9px !important;
    float: none;
  }
  .o-aside .nav-label {
    display: none;
  }
  .o-aside .sub-menu .nav-label {
    display: block;
  }
  .o-aside > nav > ul > li > .o-block .nav-arrow {
    display: none;
  }
  .sub-menu {
    background: $color-darker-purple;
    border-radius: 0px 10px 10px 0px;
  }
  .nav-arrow {
    display: none;
  }
}
