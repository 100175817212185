.long-arrow-right,
.long-arrow-left {
  display: inline-block;
  margin: auto;
  width: 12px;
  height: 12px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  cursor: pointer;
}

.long-arrow-right {
  transform: rotate(135deg);
}

.long-arrow-left {
  transform: rotate(-45deg);
}

.long-arrow-right::after,
.long-arrow-left::after {
  content: "";
  display: inline-block;
  width: 2px;
  height: 15px;
  background-color: black;
  transform: rotate(-45deg) translate(6px, 1px);
  left: 0;
  top: 0;
}
