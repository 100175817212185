.d-flex {
  display: flex;

  &-item {
    &--auto {
      flex: auto
    }

    &--flex-grow {
      flex-grow: 1;
    }
  }

  &--col {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }

  &--row-reverse {
    flex-direction: row-reverse;
  }

  &--column {
    flex-direction: column;
  }

  &--column-reverse {
    flex-direction: column-reverse;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--no-wrap {
    flex-wrap: nowrap;
  }

  &--wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  &--one {
    flex: 1;
  }
}

.jc {
  &--flex-start {
    justify-content: flex-start;
  }

  &--flex-end {
    justify-content: flex-end;
  }

  &--center {
    justify-content: center;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--space-around {
    justify-content: space-around;
  }

  &--space-evenly {
    justify-content: space-evenly;
  }
}

.ai-center {
  align-items: center;
}

.ai-start {
  align-items: start;
}

.ai-flex-end {
  align-items: flex-end;
}

.ai-baseline {
  align-items: baseline;
}

.as-start {
  align-self: flex-start;
}

.d-grid {
  display: grid;

  &--gap-1 {
    gap: 0.25rem
  }

  &--gap-2 {
    gap: 0.50rem
  }

  &--gap-3 {
    gap: 0.750rem
  }

  &--gap-4 {
    gap: 1rem
  }

  &--cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));

    &--auto {
      grid-template-columns: repeat(1, auto);
    }
  }

  &--cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    &--auto {
      grid-template-columns: repeat(2, auto);
    }
  }

  &--cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    &--auto {
      grid-template-columns: repeat(3, auto);
    }
  }

  &--cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    &--auto {
      grid-template-columns: repeat(4, auto);
    }
  }

  &--responsive {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  &--column-gap-small {
    column-gap: 0.750rem;
  }

  &--row-gap-small {
    row-gap: 0.750em;
  }
}

.border-none {
  border: 0 !important
}

@media (max-width: 1415px) {
  .d-grid--tablet-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 996px) {
  .d-grid--mobile-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .d-flex-direction--mobile-column {
    flex-direction: column;
  }
  .d-grid--mobile-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}


.w-full {
  width: 100% !important;
  white-space: nowrap;
}


.h-full {
  height: 100% !important;
}

