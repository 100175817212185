.dual-selector-widget {
  display: flex;

  &--opt-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--opt-section {
    margin-top: 19px;
  }

  &--item {
    width: 100%;
    height: 49px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &--selected {
      background-color: $ps-color-dual-selector-selected;
    }
  }

  &--box {
    width: 558px;
    height: 474px;
    padding: 30px 30px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  &--box-distance {
    margin-left: 16px;
  }

  &--full {
    height: 100%;
  }
}
