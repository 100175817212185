/*
* Paddings
*/

/*
* Left Paddings
*/
.ps-pl-1 {
  padding-left: 8px;
}

.ps-pl-2 {
  padding-left: 16px;
}

.ps-pl-3 {
  padding-left: 20px;
}

.ps-pl-4 {
  padding-left: 26px;
}

.ps-pl-5 {
  padding-left: 34px;
}

/*
* Right Paddings
*/

/*
* Margins
*/

/*
* Top Margin
*/
.ps-mt-2 {
  margin-top: 16px;
}

.ps-mt-3 {
  margin-top: 30px !important;
}

.ps-mt-4 {
  margin-top: 50px;
}

.ps-mb-4 {
  margin-bottom: 50px !important;
}

/*
* Left Margin
*/
.ps-m-0 {
  margin: 0 !important;
}

.ps-ml-1 {
  margin-left: 8px;
}

.ps-ml-2 {
  margin-left: 16px;
}

.ps-ml-3 {
  margin-left: 20px;
}

.ps-ml-xl {
  margin-left: 244px;
}

/*
* Right Margin
*/
.ps-mr-1 {
  margin-right: 8px;
}

.ps-mr-2 {
  margin-right: 16px;
}

.ps-mr-3 {
  margin-right: 20px;
}

/*
* Dynamic sizes
*/

@mixin dynamic-property($property, $direction, $value) {
  #{$property}-#{$direction}: #{$value}px;
}

@mixin generate-dynamic-classes($property, $direction) {
  $shortPropertyDirection: str-slice($property, 1, 1) + str-slice($direction, 1, 1);

  @for $i from 0 through 500 {
    .ps-#{$shortPropertyDirection}-\[#{$i}\] {
      @include dynamic-property($property, $direction, $i);
    }
  }
}

@mixin dynamic-full-property($property, $value, $unit: '') {
  #{$property}: #{$value}#{$unit};
}

@mixin generate-dynamic-full-class($property, $unit: '') {
  $shortProperty: str-slice($property, 1, 1);

  @for $i from 0 through 500 {
    .ps-#{$shortProperty}-\[#{$i}\] {
      @include dynamic-full-property($property, $i, $unit);
    }
  }
}

// Generate classes for padding
@include generate-dynamic-classes(padding, left);
@include generate-dynamic-classes(padding, right);
@include generate-dynamic-classes(padding, top);
@include generate-dynamic-classes(padding, bottom);

// Generate classes for margin
@include generate-dynamic-classes(margin, left);
@include generate-dynamic-classes(margin, right);
@include generate-dynamic-classes(margin, top);
@include generate-dynamic-classes(margin, bottom);

// Generate classes
@include generate-dynamic-full-class(width, px);
@include generate-dynamic-full-class(height, px);
@include generate-dynamic-full-class(padding, px);
@include generate-dynamic-full-class(margin, px);
