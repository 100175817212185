/* ==========================================================================
   #TEXT
   ========================================================================== */

/**
 * Default text styling
 */

body {
  color: $ps-generic-colort-text;
  font-family: $montserrat;
  font-weight: 500;
  @include inuit-font-size(14px, 24px);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

a {
  text-decoration: none !important;
  color: inherit;
}

a:hover {
  color: inherit;
}

button {
  outline: none;
}

.white-space {
  &--normal {
    white-space: normal;

    &-forced {
      white-space: normal !important;
    }
  }
}

.word-wrap {
  &--break-word {
    word-wrap: break-word;
  }
}

.disabled-greyed {
  opacity: 0.5;
  cursor: not-allowed !important;
}
