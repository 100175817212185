/* ==========================================================================
   #PANEL
   ========================================================================== */

/**
 * Default styling for panel component.
 */

.c-panel {
  &--clickable {
    &:hover {
      background: $color-generic-hover;
      cursor: pointer;
    }
  }
  .centered-image {
    position: absolute;
    top: -30px;
    left: 50%;
    margin-left: -30px;
  }
  &__holder {
    .control {
      position: absolute;
      bottom: 20px;
      left: 15px;
      margin-left: -$inuit-global-spacing-unit;
      width: 100%;
      .error__wrapper {
        position: absolute;
        top: 35px;
        max-height: 60px;
        overflow-y: auto;
      }
    }
  }
  &.u-bg--alabaster > p {
    word-break: break-all;
  }
}
