@import 'settings/settings.colors';
@import './base';
@import './tools/flag-icons.min.css';
// Fix for scroll bar on mac
@import './scrollfix.css';

// Locally all sheets are loaded (deployed it will only be one, so these won't matter)
// But we just need to set some defaults here, so locally they won't be the second to last
// generated branding file
.nav-icon,
[class^='icon--'],
svg[class^='icon-'] {
  filter: inherit;
}

.employee-avatar .c-avatar .c-avatar {
  background-color: $color-valhalla !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: $color-curious !important;
}
