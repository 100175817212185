/* ==========================================================================
   #LOGO
   ========================================================================== */

/**
 * Default styling for logo positioning.
 */

.c-logo {
  margin: 0 auto;
  &--full {
    width: 118.75px;
    height: 29.77px;
    background: url('/img/logo/payslip-logo.svg') no-repeat center center;
    background-size: 100%;
    &--large {
      width: 200px;
      height: 51.417847135px;
    }
  }
  &--short {
    display: none;
    width: 30px;
    height: 25.443134594px;
    background: url('/img/logo/payslip-logo-short.svg') no-repeat center center;
    background-size: 100%;
  }

  &--negative {
    &--full {
      width: 118.75px;
      height: 29.77px;
      margin-left: 15px;
      background: url('/img/logo/payslip-logo-negative.svg') no-repeat center center;
      background-size: 100%;
    }
    &--short {
      display: none;
      width: 30px;
      height: 25.443037966px;
      background: url('/img/logo/payslip-logo-negative-short.svg') no-repeat center center;
      background-size: 100%;
    }
  }
}

/**
 * Default styling for logo positioning when aside section is collapsed
 */

.o-aside--collapsed {
  .c-logo--negative--full {
    display: none;
  }
  .c-logo--negative--short {
    display: block;
  }
}

.logo-component {
  display: flex;
  height: 78px;
  align-items: center;
  margin-bottom: 26px;
}

.powered-by-payslip {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: smaller;
  color: $color-lilac;
  @include mq($until: wide) {
    bottom: 0;
    margin-top: 10px;
  }
  @media (max-height: 780px) {
    bottom: 0;
    margin-top: 10px;
  }
}

.payslip-logo {
  width: 57px;
  margin-left: 7px;
  background: url('/img/logo/payslip-logo-negative.svg') no-repeat center center;
  height: 22px;
  background-size: 100%;
}
