.c-nav-bar {
  position: relative;
  cursor: pointer;
  .c-avatar {
    @include mq($until: desktop) {
      width: 36px !important;
      height: 36px !important;
      > div {
        width: 36px !important;
        height: 36px !important;
        font-size: 14px;
        line-height: 36px !important;
      }
    }
  }
  .icon-arrow {
    position: absolute;
    top: 50%;
    right: -18px;
    margin-top: -7px;
    width: 15px;
    height: 15px;
    background: url('/img/icons/arrow-icon.svg') no-repeat center center;
    background-size: 15px 10px;
  }
  &__icon {
    margin: 0 auto;
    position: relative;
    width: 36px;
    height: 36px;
    &--circle {
      border-radius: 50%;
    }
  }
  &__icon--mail {
    background: url('/img/icons/mail-icon.svg') $color-white no-repeat center center;
  }
  &__icon--briefcase {
    background: url('/img/icons/briefcase-icon.svg') $color-white no-repeat center center;
  }
  &__icon--bell {
    background: url('/img/icons/bell-icon.svg') no-repeat center center;
  }
  &__icon--note-minsk {
    background: url('/img/icons/notes-icon-minsk.svg') $color-white no-repeat center center;
    background-size: 27px;
  }
  &__icon--payroll-minsk {
    background: url('/img/icons/payroll-icon-minsk.svg') no-repeat center center;
    background-size: 30px;
  }
  &__icon--document-minsk {
    background: url('/img/icons/file-icon-minsk.svg') no-repeat center center;
    background-size: 24px;
  }
  &__icon--reporting-minsk {
    background: url('/img/icons/reporting-icon-minsk.svg') no-repeat center center;
    background-size: 30px;
  }

  &__indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: 1;
    visibility: visible;
    margin-top: -2px;
  }
  &__dropdown {
    display: none;
    position: absolute;
    top: 35px;
    right: 0;
    background: $color-white;
    width: 100%;
    z-index: 999999;
    margin-right: -10px;
    border: 1px solid $color-mischka;
    @extend .rounded-md;

    @include mq($until: desktop) {
      width: 250px;
      top: 35px;
    }
    &--fixed-width {
      width: 250px;
    }
    &--fixed-width-medium {
      width: 170px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin-top: -20px;
      width: 100%;
      height: 20px;
      background-color: transparent;
    }
  }
  &:hover {
    .icon-arrow {
      transform: rotate(-180deg);
    }

    .c-nav-bar__dropdown {
      display: block;
    }

    .c-nav-bar__indicator ~ svg {
      path {
        fill: $color-curious !important;
      }
    }

    .icon-polygon {
      transform: rotate(180deg);
    }
  }
  .c-nav li {
    border-bottom: none;
    padding: 15px;
    line-height: 15px;

    &:last-of-type:not(:only-child) {
      border-top: 1px solid $color-mischka;
    }

    &:hover {
      background-color: $color-alabaster;

      span {
        color: $color-curious;
      }

      svg {
        circle, path:not(.without-fill) {
          fill: $color-curious !important;
        }
        path.without-fill {
          stroke: $color-curious !important;
        }
      }
    }

    .nav-label {
      color: $color-valhalla;
    }

    &:last-of-type.no-bg {
      &:hover {
        background-color: $color-white;
      }
    }

  }

  &-profile {
    .c-nav-bar__dropdown {
      top: 45px;
      right: 15px;
    }
  }

  &--widget {
    &:hover {
      .u-text--minsk {
        color: $color-white !important;
      }
    }
  }
}
