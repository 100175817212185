/* ==========================================================================
   #GRID
   ========================================================================== */

/**
 * Addition to a flexbox grid for inuitcss.
 * https://github.com/nicoqh/inuit-flexgrid
 */

// Specify the width of the gutters. By default we use the
// global spacing unit provided by inuitcss.

.o-grid {
  &--strech {
    display: flex;
    align-content: stretch;
    flex-wrap: wrap;
    .c-panel {
      min-height: 100%;
    }
    .c-panel.min-height-72 {
      min-height: 72px;
      /* Fix for IE */
      height: 72px;
      overflow-y: auto;
    }
    .min-height-74 {
      min-height: 74px;
      height: auto;
    }
  }
  &--half {
    width: 50%;
  }
  &--inline-flex {
    display: inline-flex;
  }
  &__basis--70 {
    flex-basis: 70%;
  }
  &__basis--30 {
    flex-basis: 30%;
  }
  &--no-wrap {
    flex-wrap: nowrap;
  }
}