/* ==========================================================================
   #BOXES
   ========================================================================== */

.c-box {
  padding:$inuit-global-spacing-unit-tiny $inuit-global-spacing-unit;
  margin: 0 $inuit-global-spacing-unit-small;
  &--tiny {
    padding: 0 8px;
    margin: 0 $inuit-global-spacing-unit-tiny 0 0;
  }
}