/* ==========================================================================
   #ASSIDE
   ========================================================================== */

/**
 * Vertical left bar aside, containing logo and menu.
 */

.o-aside {
  position: fixed;
  width: 190px;
  top: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, #3a3048 0%, #1f172b 30.21%);
  border-radius: 0px 25px 25px 0px;
  z-index: 10;
  transition: none;
  .icon-multi-arrow {
    position: absolute;
    bottom: $inuit-global-spacing-unit-large;
    right: $inuit-global-spacing-unit-small;
    width: 30px;
    height: 20px;
    cursor: pointer;
    transition: none;
    &:hover {
      transition: all 300ms ease-in;
    }
  }
}

/**
 * Default styling for aside section when it is collapsed
 */

.o-aside--collapsed {
  .o-aside {
    width: 65px;
    .icon-multi-arrow {
      transform: rotate(180deg);
    }
  }
}
