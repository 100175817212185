/* ==========================================================================
   #Login
   ========================================================================== */

.c-login {
  &-wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background-color: $color-white;
    overflow: hidden;
    overflow: overlay;

    .o-form-group {
      .error {
        line-height: 12px;
      }
    }

    .c-form--state {
      position: relative;
      width: 100%;

      & > .error {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: auto;
        margin: 0 auto 55px auto;
        line-height: 12px;
      }
    }
  }

  &__image {
    position: relative;
    width: 60%;
    height: 100%;
    background-size: 0 0;


    .login-img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      min-width: 1100px;
      width: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      clip-path: circle(77.2% at 23.7% 56.5%);
    }

    .login-img-description {
      position: absolute;
      bottom: 30px;
      left: 30px;
      width: auto;
      background: rgba(255, 255, 255, 0.7);
      z-index: 3;
      padding: 2px 8px;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      @extend .rounded;
    }
  }

  &__content {
    flex: 1;
    padding: 90px 24px 20px 15px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .c-logo {
      margin-top: 50px;
      margin-bottom: 30px;
    }

    .o-form-group {
      max-width: 286px;
      position: relative;
    }

    .o-grid {
      max-width: 286px;
      margin: 0 auto;
    }

    p {
      line-height: 17px;
      width: 100%;
    }

    &-forgotten-pass {
      margin-top: 91px;

      .c-label {
        padding-bottom: 35px;
      }

      .c-btn {
        margin-bottom: 23px;
      }
    }

    &-user-activation {
      position: relative;
      margin-top: 81px;

      .c-label {
        display: inline-block;
        margin-bottom: 17px;
      }

      .c-btn {
        margin-bottom: 27px;
      }

      .c-form--state > .error {
        margin-bottom: 83px !important;
      }
    }

    &-sso {
      max-width: 284px;
      margin: 0 auto;

      .c-btn {
        margin-top: 175px;
        margin-bottom: 25px;
      }
    }

    &-icons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 90px auto 0;
      flex-wrap: nowrap;
      max-width: 335px;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      span {
        margin-bottom: 11px;

      }

      p {
        text-align: center;
        line-height: 17px;
        font-weight: 400;
        color: $color-minsk;
      }
    }

    &--reset-pass {
      overflow-y: scroll;
      padding-top: 75px;

      .c-logo {
        margin-top: 28px;
      }

      .c-btn {
        margin-top: 30px !important;
      }
    }
  }

  @media (min-width: 800px) and (min-height: 1000px) {
    &__content {
      justify-content: center;
    }
  }

  @media (max-width: 1900px) and (min-height: 1000px) {
    &__image {
      .login-img {
        min-width: 1400px;
      }
    }
  }

  @media (max-width: 2500px) and (min-height: 1400px) {
    &__image {
      .login-img {
        min-width: 1500px;
      }
    }
  }

  @media (max-width: 2300px) and (min-height: 1200px) {
    &__image {
      .login-img {
        min-width: 1500px;
      }
    }
  }

  @media (max-width: 2600px) and (min-height: 1500px) {
    &__image {
      .login-img {
        min-width: 1600px;
      }
    }
  }

  @media (min-width: 2600px) and (min-height: 1500px) {
    &__image {
      .login-img {
        min-width: 1900px;
      }
    }
  }

  @media (max-width: 1200px) {
    &__content {
      &--reset-pass {
        padding-top: 25px;
      }
    }
  }

  @media (max-device-width: 992px) {
    &__content {
      flex: 0 0 380px;
    }
  }

  @media (max-width: 800px) {
    &-wrapper {
      flex-direction: column;
      height: 100vh;
      overflow: auto;
    }

    &__image {
      width: 100%;
      height: 0;
      padding-top: 54.166666666667%;
      background-size: 100%;
      background-size: cover;
      clip-path: circle(100% at 49% -62%);
      background-position-y: bottom;

      .login-img {
        opacity: 0;
        visibility: hidden;
      }

      .login-img-description {
        top: 20px;
        right: 20px;
        bottom: auto;
        left: auto;
      }
    }

    &__content {
      padding: 20px;
      overflow: initial;
      flex: 1;

      .c-logo {
        margin-top: 0;
        margin-bottom: 0;
      }

      .c-btn {
        margin-top: 0 !important;
      }

      &-icons {
        margin-top: 54px;
      }

      &--reset-pass {
        .c-logo {
          margin-bottom: 24px;
        }
      }
    }
  }

  @media (max-device-width: 432px) {
    &__content {
      justify-content: center;

      &-icons {
        display: none;
      }
    }
  }
}

