///* ========================================================================
//   #FONTS
//   ======================================================================== */
// The fonts settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Standardise some fonts.
$montserrat: 'Montserrat', sans-serif;
$font-size-normal: 14px;

.fs {
  &--normal {
    font-size: $font-size-normal;
  }
}
