.note-item {
  padding: 18px 20px 18px 15px;
  &-content {
    white-space: break-spaces;
  }
  &-text {
    word-break: break-all;
  }
}

.note-list-create-container {
  height: 100%;
  .note-list-create-text {
    height: 100%;
  }
  form {
    button[type='submit'] {
      bottom: -45px;
      right: 0;
    }
    .c-textarea {
      border: none;
    }
    .c-textarea::placeholder {
      color: black; /*Change the placeholder color*/
      opacity: 0.5; /*Change the opacity between 0 and 1*/
    }
  }
}

.note-item {
  &-text-container {
    grid-template-columns: auto auto auto;
  }

  &-actions {
    min-width: 260px
  }

  &-date {
    width: 150px;
  }
}

.notes-list-container {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}

.notes-close-icon {
  right: 10px;
  top: 10px;
}

@media (max-width: 1200px) {
  .note-item {
    &-owner {
      padding-right: 30px;
    }

    &-title-row {
      flex-wrap: wrap;
    }
  }
}

