/* ==========================================================================
   #REACT DATEPICKER
   ========================================================================== */

/**
 * Additional custom styling for react-datepicker.
 */

.react-datepicker {
  transition: none !important;
  &__month-dropdown-container {
    margin-right: 1.25rem;
  }
  &__month-read-view--down-arrow,
  &__year-read-view--down-arrow {
    top: 5px;
  }
  &__tether-element,
  &__triangle {
    transition: none;
  }
  &__input-container {
    display: block;
  }
  &__day--outside-month {
    opacity: 0.5;
  }
  &__day--selected,
  &__day--in-selecting-range,
  &__day--in-range,
  &__day--keyboard-selected {
    background-color: $color-alto;
    color: inherit;
    &:hover {
      background-color: $color-gallery;
    }
  }
  &__day--highlighted-curious {
    color: $color-curious;
  }
  &__day--highlighted-inherit {
    color: inherit;
  }
  &__day--today {
    background-color: $color-curious;
    color: $color-white;
    border-radius: 0.3rem;
    &:hover {
      background-color: $color-curious-hover;
    }
  }
}
.react-datepicker {
  &-popper {
    z-index: 10000001;
    transition: none !important;
  }
  &__day--disabled {
    position: relative;
  }
  &.report-show-disabled-message {
    .react-datepicker__day--disabled::after {
      background-color: white;
      border: 1px solid $color-tuna;
      color: $color-lunar;
      display: none;
      padding: 10px 15px;
      position: absolute;
      text-align: center;
      z-index: 999;
      content: 'Data for this report is available from 6th Jan 2022.';
      top: 0;
      left: 50%;
      transform: translate(-50%, calc(-100% - 10px));
      line-height: 14px;
      min-width: 150px;
    }
    .react-datepicker__day--disabled::before {
      content: ' ';
      background-color: $color-tuna;
      display: none;
      position: absolute;
      height: 15px;
      width: 15px;
      transform: translate(30%, calc(-100% - 5px)) rotate(45deg);
      z-index: 999;
    }
    .react-datepicker__day--disabled:hover::after {
      display: block;
    }
    .react-datepicker__day--disabled:hover::before {
      display: block;
    }
  }
  &__day--disabled.react-datepicker__day--outside-month {
    opacity: 1;
  }
}

.o-table thead th * > .react-datepicker {
  font-weight: 500;
}

.datepicker-column-filter * > .react-datepicker__close-icon::after {
  color: #999;
  background: transparent;
}
