/* ==========================================================================
   #FORMS
   ========================================================================== */

/**
 * Additional styles for manipulating the structure of HTML `forms`s and their elements.
 */

.o-form-group {
  margin-bottom: $inuit-global-spacing-unit-small;

  &__errors--abs {
    .error {
      position: absolute;
    }
  }

  &--in-select {
    .control {
      padding-left: 10px;
    }
  }
}

/*
* Schema form
*/
.schema-form {
  &--grid > fieldset {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  }

  &--gr-4 {
    grid-row: 4;
  }

  &--item {
    > label {
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
    }
  }

  &--select-widget:not(.is-open).has-value {
    max-height: 180px;
    overflow-y: auto;
  }

  &--select-widget-container {
    &--value {
      & > .is-open > .Select-control {
        max-height: 250px;
        display: block;
      }
    }
  }

}


.schema-form--select-widget.Select--multi {
  .Select-control .Select-value {
    color: $color-white;
    @extend .rounded-sm;
    line-height: 17px;
    background-color: $color-curious;
    font-size: 9px;
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    vertical-align: top;
  }

  .Select-control .Select-value-label {
    word-break: break-all;
  }

  .Select-value {
    padding-right: 0 !important;
  }

  .Select-clear-zone, .Select-arrow-zone {
    vertical-align: top !important;
  }

  .Select-option > label.control {
    margin-right: 0 !important;
  }

}

