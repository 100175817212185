/* ==========================================================================
   #MEDIA
   ========================================================================== */

/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 * Custom media additional styles
 */

.o-media {
  position: relative;  
}

.o-media__img {
  &--circle {
    border-radius: 50%;
  }
  &--shadow {
    box-shadow: 0 0 10px $color-lunar;
  }
}
