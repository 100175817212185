/* ==========================================================================
   #HELPER
   ========================================================================== */

/**
 * A series of helper classes to use arbitrarily. Only use a helper class if an
 * element/component doesn’t already have a class to which you could apply this
 * styling, e.g. if you need to float `.main-nav` left then add `float:left;` to
 * that ruleset as opposed to adding the `.float--left` class to the markup.
 *
 * A lot of these classes carry `!important` as you will always want them to win
 * out over other selectors.
 */

/**
 * Add/remove floats
 */
.u-float--right {
  float: right;
}
.u-float--left {
  float: left;
}
.u-float--none {
  float: none;
}

/**
 * Text alignment
 */
.u-text--left {
  text-align: left !important;
}
.u-text--center {
  text-align: center !important;
}
.u-text--right {
  text-align: right !important;
}

/**
 * Font weights
 */
.u-weight--regular {
  font-weight: 400;
}

.u-weight--normal {
  font-weight: 500;
}

.u-weight--medium {
  font-weight: 600;
}

.u-weight--bold {
  font-weight: 700;
}

/**
 * Font size
 */

.u-text--mini {
  @include inuit-font-size(8px, 16px);
}

.u-text--tiny {
  @include inuit-font-size(10px, 18px);
}

.u-text--small {
  @include inuit-font-size(12px, 20px);
  font-size: 12px !important;
}

.u-text--medium {
  @include inuit-font-size(13px, 22px);
  font-size: 13px !important;
}

.u-text--normal {
  @include inuit-font-size(14px, 24px);
}

.u-text--15px {
  @include inuit-font-size(15px, 25px);
}

.u-text--large {
  @include inuit-font-size(16px, 26px);
}

.u-text--huge {
  @include inuit-font-size(18px, 28px);
}

.u-text--xtra-huge {
  @include inuit-font-size(20px, 30px);
}

.u-text--24px {
  @include inuit-font-size(24px, 34px);
}

.u-text--26px {
  @include inuit-font-size(26px, 36px);
}

.u-text--giant {
  @include inuit-font-size(40px, 50px);
}

.u-text--enormous {
  @include inuit-font-size(150px, 160px);
  @include mq($until: desktop) {
    @include inuit-font-size(50px, 60px);
  }
}

/**
 * Font color
 */

.u-text-generic {
  color: $ps-th-text-color;
}
.u-text--curious {
  color: $color-curious;
}

.u-text--mandy {
  color: $color-mandy;
}

.u-text--supernova {
  color: $color-supernova;
}

.u-text--minsk {
  color: $color-minsk;
}

.u-text--white {
  color: $color-white;
}

.u-text--chelsea {
  color: $color-chelsea;
}

.u-text--valhalla {
  color: $color-valhalla;
}

.u-text--highlight {
  color: $color-highlighter;
}

/**
 * Line height
 */

.line-height--10 {
  line-height: 10px;
}

.line-height--15 {
  line-height: 15px;
}

.line-height--17 {
  line-height: 17px;
}

.line-height--20 {
  line-height: 20px;
}

.line-height--24 {
  line-height: 24px !important;
}

.line-height--27 {
  line-height: 27px;
}
.line-height--49 {
  line-height: 49px;
}

/**
 * Text decoration
 */

.u-line-through {
  text-decoration: line-through;
}

.u-underline {
  text-decoration: underline !important;
}

/**
 * Background color
 */

.u-bg--white {
  background-color: $color-white;
}

.u-bg--alto {
  background-color: $color-alto;
}

.u-bg--gallery {
  background-color: $color-gallery;
}

.u-bg--alabaster {
  background-color: $color-alabaster;
}

.u-bg--curious {
  background-color: $color-curious;
}

.u-bg--mandy {
  background-color: $color-mandy;
}

.u-bg--supernova {
  background-color: $color-supernova;
}

.u-bg--minsk {
  background-color: $color-minsk;
}

.u-bg--generic-dark-gray {
  background-color: $ps-time-attendece;
}

.u-bg--chatelle {
  background-color: $color-chatelle;
}

.u-bg--placebo {
  background-color: $color-placebo;
}

.u-bg--transparent {
  background-color: transparent;
}

.u-bg--pink {
  background-color: $color-pink;
}

.u-bg--light-green {
  background-color: $color-light-green;
}

.u-bg--blue {
  background-color: $color-blue;
}

.u-bg--orange {
  background-color: $color-orange;
}

.u-bg--mid-grey {
  background-color: $color-mid-grey;
}

.u-bg--lilac {
  background-color: $ps-color-light-gray !important;
}

.u-bg--sauve {
  background-color: $color-sauve;
}

.u-bg--valhalla {
  background-color: $color-valhalla;
}

.u-bg--lightest-purple {
  background: $color-generic-hover;
}

.u-bg--color-generic-hover {
  background: $color-generic-hover !important;
}

/**
 * Font case
 */

.u-uppercase {
  text-transform: uppercase;
}

.u-lowercase {
  text-transform: lowercase;
}

.u-capitalize {
  text-transform: capitalize;
}

/**
 * Position
 */

.u-relative {
  position: relative;
}

.u-absolute {
  position: absolute;
  &--history-btn {
    top: 36px;
    right: 12px;
  }
}

.u-absolute--top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.u-absolute--vertically-centered {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/**
 * Border
 */

.u-border-bottom--fountain {
  border-bottom: 1px solid $color-fountain;
}

.u-border-left--minsk-1 {
  border-left: 1px solid $color-minsk;
}

.u-border-right--minsk-1 {
  border-right: 1px solid $color-minsk;
}

.u-border-left--minsk-2 {
  border-left: 2px solid $color-minsk;
}

.u-border-right--minsk-2 {
  border-right: 2px solid $color-minsk;
}

.u-border-bottom--minsk {
  border-bottom: 1px solid $color-minsk;
}

.u-border-left--white-1 {
  border-left: 1px solid $color-white;
}

.u-border-right--white {
  border-right: 1px solid $color-white;
}

.u-border-right--white-2 {
  border-right: 2px solid $color-white;
}

.u-border-bottom--alto-1 {
  border-bottom: 1px solid $color-alto;
}

.u-border-right--alto-1 {
  border-right: 1px solid $color-alto;
}

.u-border-bottom--supernova-5 {
  border-bottom: 5px solid $color-supernova;
}

.u-border--mischka {
  border: 1px solid $color-mischka;
}

.u-border-bottom--mischka {
  border-bottom: 1px solid $color-mischka;
}

.u-border-bottom--mischka--special-sizing {
  // Due to zooming browsers rendering borders ever so slightly larger and round up
  // so on a zoom a browser that shows 1px bumps to '1.2' which rounds up to 2px
  // It's not on all (rows) and is sporadic.
  // I don't like this fix.. But it 'looks' like it might work
  // #modern problems require modern solutions
  border-bottom: 0.01px solid $color-mischka;
}
.u-border-bottom--curious {
  border-bottom: 1px solid $color-curious;
}

.u-border--transparent-3 {
  border: 3px solid transparent;
}

.u-border-none {
  border: none !important;
}

/**
 * Cursor
 */
.u-cursor--default {
  cursor: default;
}

.u-cursor--pointer {
  cursor: pointer;
}

.u-cursor--not-allowed {
  cursor: not-allowed;
}

.pe-none {
  pointer-events: none;
}

/**
 * Font style
 */
.u-text--italic {
  font-style: italic;
}

/**
 * Font style
 */
.u-index--on-top {
  z-index: 999;
}

/**
 * Overflows
 */
.u-text--overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: inherit !important;
  &--nowrap {
    white-space: nowrap !important;
  }
}

.u-text--overflow-wrap {
  overflow-wrap: break-word;
}

.u-white-space-pre-line {
  white-space: pre-line !important;
}

.u-overflow-y-auto {
  overflow-y: auto;
}

.u-overflow-y-hidden {
  overflow-y: hidden !important;
}

.u-text-overflow--unset {
  text-overflow: unset !important;
}

/** White Space */
.u-white-space {
  &--unset {
    white-space: unset !important;
  }
}

/**
 * Image classes
 */
.u-img--fluid {
  width: 100%;
  height: auto;
}

/*
 * Additional global spacing unit size and margin/padding declarations
 */
$inuit-global-spacing-unit-tiny-half: 3px;

.u-margin-top-section {
  margin-top: 30px;
}

.u-margin-top-tiny-half {
  margin-top: $inuit-global-spacing-unit-tiny-half !important;
}

.u-margin-top-neg-tiny {
  margin-top: -$inuit-global-spacing-unit-tiny !important;
}

.u-margin-top-neg {
  margin-top: -$inuit-global-spacing-unit !important;
}

.u-margin-top-neg-large {
  margin-top: -$inuit-global-spacing-unit-large !important;
}

.u-margin-top-neg-25 {
  margin-top: -25px;
}

.u-margin-bottom-neg-large {
  margin-bottom: -$inuit-global-spacing-unit-large !important;
}

.u-margin-bottom-neg-tiny {
  margin-bottom: -$inuit-global-spacing-unit-tiny !important;
}

.u-margin-left-neg-small {
  margin-left: -$inuit-global-spacing-unit-small !important;
}

.margin-left--8px {
  margin-left: 8px;
}

.forced-padding-left--12px {
  padding-left: 12px !important;
}
.forced-padding-left--14px {
  padding-left: 14px !important;
}
.forced-padding-left--24px {
  padding-left: 24px !important;
}
.forced-padding-right--8px {
  padding-right: 8px !important;
}
.forced-padding-bottom--24px {
  padding-bottom: 24px !important;
}

.forced-padding-bottom--42px {
  padding-bottom: 42px !important;
}

.forced-padding-x--12px {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.forced-padding-x--15px {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
/**
 * Display inline
 */
.o-block--inline {
  display: inline;
}

/**
 * Display inline block
 */
.o-block--inline-block {
  display: inline-block;
}

// Vertical alignment
.u-align-top {
  vertical-align: top !important;
}

// Flex alignment
.u-align-self {
  &--center {
    align-self: center;
  }
}

.u-flex-grow {
  &--1 {
    flex-grow: 1;
  }
}

.u-half-opacity {
  opacity: 0.5;
}

.u-zero-opacity {
  opacity: 0;
}

/**
 * Width
 */

.u-margin--auto {
  margin: 0 auto !important;
}

.u-min-width-200 {
  min-width: 200px;
}

.u-min-width-250 {
  min-width: 250px;
}

.u-min-width-300 {
  min-width: 300px;
}
.u-fixed-width--10 {
  width: 10px;
}
.u-fixed-width--15 {
  width: 15px;
}
.u-fixed-width--20 {
  width: 20px;
}
.u-fixed-width--25 {
  width: 25px;
}
.u-fixed-width--30 {
  width: 30px;
}
.u-fixed-width--50 {
  width: 50px;
}
.u-fixed-width--75 {
  width: 75px;
}
.u-fixed-width--100 {
  width: 100px;
}
.u-fixed-width--125 {
  width: 125px;
}
.u-fixed-width--200 {
  width: 200px;
}
.u-fixed-width--250 {
  width: 250px;
}
.u-width-95pc {
  width: 95%;
}

/**
* Height
*/
.u-height-0 {
  height: 0;
}

.u-height-60vh {
  max-height: 60vh;
}

/**
* Overflow
*/

.u-overflow-x-hidden {
  overflow-x: hidden;
}

.bank-account-table,
.employee-user-address-table {
  .o-layout {
    position: relative;
  }

  .field-value {
    span {
      font-weight: 400;
    }
  }

  tbody tr:nth-of-type(1) {
    background-color: rgb(235, 235, 235);

    .field-name {
      width: 100% !important;
      text-align: center;

      span {
        color: $color-lunar;
        font-weight: 400;
      }
    }

    .field-value {
      position: absolute !important;
      text-align: end;
      right: 0;

      span {
        line-height: 17px !important;
      }
    }
  }
}

/**
* Visibility
*/
.v-hidden {
  visibility: hidden;
}
