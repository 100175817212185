.o-payslip-table-container {
  .o-payslip-table {
    table > thead > tr > th:not(.text-center),
    table > thead > tr > th:not(.u-text--center) {
      padding-left: 6px;
    }
    table > tbody > tr > td {
      .c-badge {
        padding-left: 0;
      }
    }
    &--payfile {
      table > tbody > tr > td {
        background-color: $color-white;
        text-align: right;
      }
      table > tbody > tr:hover {
        td {
          background-color: $color-generic-hover;
        }
      }
      table > thead > tr > th:first-child {
        width: 4px;
        padding: 0;
        border-top-left-radius: 5px;
      }
      table > tbody > tr > td:first-child {
        width: 4px;
        min-width: 4px;
        padding: 0;
      }
      table > tbody > tr > td:nth-child(1),
      table > thead > tr:nth-child(1) > th:nth-child(1),
      table > thead > tr:nth-child(2) > th:nth-child(1),
      table > tfoot > tr:nth-child(1) > th:nth-child(1),
      table > tbody > tr > td:nth-child(2),
      table > thead > tr:nth-child(1) > th:nth-child(2),
      table > thead > tr:nth-child(2) > th:nth-child(2),
      table > tfoot > tr:nth-child(1) > th:nth-child(2),
      table > tbody > tr > td:nth-child(3),
      table > tfoot > tr:nth-child(1) > th:nth-child(3),
      table > thead > tr:nth-child(1) > th:nth-child(3),
      table > thead > tr:nth-child(2) > th:nth-child(3),
      table > tbody > tr > td:nth-child(4),
      table > tfoot > tr:nth-child(1) > th:nth-child(4),
      table > thead > tr:nth-child(1) > th:nth-child(4),
      table > thead > tr:nth-child(2) > th:nth-child(4),
      table > tbody > tr > td:nth-child(5),
      table > tfoot > tr:nth-child(1) > th:nth-child(5),
      table > thead > tr:nth-child(1) > th:nth-child(5),
      table > thead > tr:nth-child(2) > th:nth-child(5),
      table > tbody > tr > td:nth-child(6),
      table > tfoot > tr:nth-child(1) > th:nth-child(6),
      table > thead > tr:nth-child(1) > th:nth-child(6),
      table > thead > tr:nth-child(2) > th:nth-child(6) {
        position: sticky;
        position: -webkit-sticky;
        z-index: 1;
        text-align: left;
      }
      table > thead > tr:nth-child(2) > th:nth-child(2),
      table > tbody > tr > td:nth-child(2),
      table > thead > tr:nth-child(2) > th:nth-child(4),
      table > tbody > tr > td:nth-child(4) {
        max-width: 110px;
        text-overflow: unset;
        white-space: normal;
        word-wrap: break-word;
      }
      table > tbody > tr > td:nth-child(1),
      table > thead > tr:nth-child(1) > th:nth-child(1),
      table > thead > tr:nth-child(2) > th:nth-child(1),
      table > tfoot > tr:nth-child(1) > th:nth-child(1) {
        left: 0;
      }
      table > tbody > tr > td:nth-child(2),
      table > thead > tr:nth-child(1) > th:nth-child(2),
      table > thead > tr:nth-child(2) > th:nth-child(2),
      table > tfoot > tr:nth-child(1) > th:nth-child(2) {
        left: 4px;
      }
      table > tbody > tr > td:nth-child(3),
      table > thead > tr:nth-child(1) > th:nth-child(3),
      table > thead > tr:nth-child(2) > th:nth-child(3),
      table > tfoot > tr:nth-child(1) > th:nth-child(3) {
        left: 114px;
      }
      table > tbody > tr > td:nth-child(4),
      table > thead > tr:nth-child(1) > th:nth-child(4),
      table > thead > tr:nth-child(2) > th:nth-child(4),
      table > tfoot > tr:nth-child(1) > th:nth-child(4) {
        left: 276px;
      }
      table > tbody > tr > td:nth-child(5),
      table > thead > tr:nth-child(1) > th:nth-child(5),
      table > thead > tr:nth-child(2) > th:nth-child(5),
      table > tfoot > tr:nth-child(1) > th:nth-child(5) {
        left: 386px;
      }
      table > tbody > tr > td:nth-child(6),
      table > thead > tr:nth-child(1) > th:nth-child(6),
      table > thead > tr:nth-child(2) > th:nth-child(6),
      table > tfoot > tr:nth-child(1) > th:nth-child(6) {
        left: 548px;
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 1px;
          background-color: rgba(41, 21, 71, 0.3);
          border-right: 1px solid transparent;
          height: calc(100% + 2px);
        }
      }
      table > thead {
        position: sticky;
        top: 0;
        z-index: 2;
      }
      table > tfoot th {
        font-size: 1rem;
      }
      /**
      * Our goal is the table to fill the entire empty height space,
      * without having a page scrollbar.
      * It's fine the scrollbar to be in the table body only.
      * This solution isn't perfect, because in the Payrun pages (--payfile),
      * we have other elements with static heights (not in vh unit).
      * Because of this if we add a new element to the page, then we have to tune-up these `max-height` values,
      * which is error-prone.
      * A better solution would be to refactor the main layout, using relative units (as vh).
      */
      &.react-bs-container-body {
        max-height: 70vh;
        // overflow-x: hidden;
        @include mq($until: wide) {
          max-height: 60vh;
        }
      }
      .react-bs-container-footer {
        overflow: auto;
      }

      .text-input {
        input {
          border: none;
          border-bottom: 1px solid transparent;
          border-bottom-color: $color-alto;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;

          &:hover {
            border-bottom-color: $color-light-blue;
          }

          &:focus {
            margin: 0;
            margin-bottom: -1px;
            border-bottom-width: 2px;
            border-bottom-color: $color-cobalt;
          }
        }
      }
    }
    &__cell {
      &--whitespace {
        &--normal {
          div {
            white-space: normal;
            word-wrap: break-word;
          }
        }
      }
    }
    table {
      margin-bottom: 0;
      table-layout: fixed;
      &.table--layout-auto {
        table-layout: auto;
      }
      &.table--layout-fixed {
        table-layout: fixed;
      }
      &.table-payrollInstances th {
        vertical-align: middle !important;
      }
    }

    .table-bordered {
      border: 0;
      outline: 0 !important;
    }
    table td {
      .Select-menu-outer {
        text-align: left;
      }
    }
    table th {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      &.white-space--unset {
        white-space: unset;
      }
      .Select-menu-outer {
        text-align: left;
      }
      .dropdown-toggle::after {
        border-top: 0.5em solid;
        border-right: 0.5em solid transparent;
        border-bottom: 0;
        border-left: 0.5em solid transparent;
        vertical-align: 0.5em;
      }
      .dropup .dropdown-toggle::after {
        vertical-align: 0;
        border-top: 0;
        border-right: 0.5em solid transparent;
        border-bottom: 0.5em solid;
        border-left: 0.5em solid transparent;
      }
      .sorting-header-text,
      .sorting-header-filter {
        margin: auto 0.3rem;
      }

      .sorting-header-grid {
        grid-template-columns: auto 1fr;
      }
      .sorting-filter-header-grid {
        grid-template-columns: auto auto 1fr;
      }
    }

    &--wrap-header-text {
      table th {
        overflow: hidden;
        white-space: unset;
        text-overflow: initial;
      }
    }

    table > thead > tr > th {
      font-size: 1rem;
      color: $ps-color-tb-head;
      background-color: $color-sauve;
      vertical-align: middle;
      .filter {
        font-weight: 400;
        min-width: 100px;
        height: 38px;
        border-color: $color-alto;
        border-radius: 5px;
      }
      &.colspan-header {
        background-color: $ps-color-tb-subheader;
      }
    }

    input.filter {
      &::placeholder {
        opacity: 0.3;
        font-style: italic;
      }
    }

    .table-bordered > tbody > tr > td:nth-child(2),
    .table-bordered > tbody > tr > th:nth-child(2),
    .table-bordered > tfoot > tr > td:nth-child(2),
    .table-bordered > tfoot > tr > th:nth-child(2),
    .table-bordered > thead > tr > th:nth-child(2) {
      border-top-width: 0;
    }

    .table-bordered > thead > tr > th:last-child {
      border-top-right-radius: 5px;
    }
    .table-bordered > thead > tr > th:first-child {
      width: 4px;
      padding: 0;
      border-top-left-radius: 5px;
    }
    .table-bordered > tbody > tr > td:first-child {
      width: 4px;
      padding: 0;
      overflow: hidden;
    }
    .table-bordered > tbody > tr {
      position: relative;
    }
    .table-bordered > tbody > tr > td:first-child::before {
      position: absolute;
      content: ' ';
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      height: 100%;
      opacity: 0;
      @extend .rounded-tr;
      @extend .rounded-br;
      transition-delay: 125ms;
    }
    .table-bordered > tbody > tr:hover > td:first-child::before {
      background-color: $ps-color-tr-delimiter;
    }
    // ! This Breaks the inline drop down.  So if anyone touches this
    // ! Make sure to view the CompanyCountryTermEdit page
    // table tr td > div {
    //   text-overflow: ellipsis;
    //   white-space: initial;
    //   overflow: hidden;
    // }
    table tr td {
      .c-badge {
        width: 100%;
      }
    }
    table th {
      min-height: 50px;
      .dropdown,
      .dropup {
        height: 15px;
        .dropdown-toggle {
          color: $color-valhalla;
        }
      }
      .order .dropdown-toggle.is-sorted {
        color: $color-curious;
      }
      .order .dropdown .dropdown-toggle.is-sorted::after {
        vertical-align: 0;
      }
    }
    table tbody tr {
      border-bottom: 0.03rem solid #dbd6e2;
      background-color: $color-white;
      transition: background-color 200ms ease-in-out;
      &.row-selected {
        background-color: $ps-color-tb-selected;
      }
      td {
        .pin-icon {
          position: relative;
          margin-left: -3.125rem;
          margin-right: 1rem;
          height: 22px;
          width: 22px;
          transition: all 350ms ease-in-out;
          cursor: pointer;
          &.pinned {
            @extend .icon--pin-icon;
            background-size: 22px !important;
            &:hover {
              @extend .icon--pin-icon-curious;
              background-size: 22px !important;
            }
          }
        }
      }
      &:not(.no-hover):hover {
        td:first-child::before {
          transition: opacity 250ms ease-in-out;
          opacity: 1;
        }
        background-color: $color-generic-hover;
        td {
          .pin-icon:not(.pinned):not(.can-pin) {
            cursor: default;
            .hover-box {
              opacity: 0;
            }
          }
          .pin-icon:not(.pinned):not(.pinning-not-allowed) {
            margin-left: -3.125rem;
            margin-right: 1rem;
            height: 22px;
            width: 22px;
            span.icon {
              @extend .icon--pin-icon;
              background-size: 22px !important;
              &:hover {
                @extend .icon--pin-icon-curious;
                background-size: 22px !important;
              }
            }
          }
        }
      }

      &.row-expanded,
      &.row-expanded + tr {
        td:first-child::before {
          transition: opacity 250ms ease-in-out;
          border-bottom-right-radius: 0;
          opacity: 1;
        }
      }
      &.row-expanded + tr {
        td:first-child::before {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0.25rem;
        }
      }
    }
  }

  .o-payslip-table-tool-bar {
    margin-bottom: 5px;
    .o-payslip-table-search-form {
      margin-bottom: 0;
    }
  }
  .o-expanded-table {
    tr td:nth-of-type(1) > span:nth-of-type(1) {
      margin-left: $inuit-global-spacing-unit-small;
      border-top-left-radius: $inuit-global-spacing-unit-tiny;
      border-bottom-left-radius: $inuit-global-spacing-unit-tiny;
    }

    tr td:last-of-type > span:nth-of-type(1) {
      margin-right: $inuit-global-spacing-unit-small;
      border-top-right-radius: $inuit-global-spacing-unit-tiny;
      border-bottom-right-radius: $inuit-global-spacing-unit-tiny;
    }
  }
}
.filter-input-wrapper .Select {
  min-width: 200px;
}

.o-table-td--tiny {
  td {
    padding: $inuit-global-spacing-unit-tiny;
  }
}
.c-modal {
  .table-bordered > tbody > tr > td:first-child::before {
    height: 93%;
  }
}

.o-expanded-table .expanded {
  .u-bg--sauve {
    background-color: rgb(248 248 253);
    border-bottom: 1px solid #dbd6e2 !important;
    font-weight: 505 !important;
    font-size: 14px !important;
    border-radius: 0 !important;
  }
}

.o-expanded-table .expanded td:first-child {
  padding-left: 55px !important ;
}
.o-table-expandable-not-hq .expanded td:first-child {
  padding-left: 6px !important;
}
.row-expanded div {
  font-weight: bold;
}
.row-expanded.u-weight--normal div {
  font-weight: 500;
}

.o-payslip-table {
  tbody {
    tr.row-is-pinned {
      border-color: $color-curious !important;
    }
  }
}

.payslip-table-error-elipsis {
  display: block;
  word-break: break-all;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
}

th .react-datepicker__input-container > .c-input {
  padding-top: 0.375rem;
  padding-right: 0.35rem;
  padding-bottom: 0.375rem;
  padding-left: 0.35rem;
}
