.o-list {
  &--striped {
    li:nth-of-type(odd) {
      background: $color-white;
    }

    li:nth-of-type(even) {
      background: $color-alabaster;
    }
  }
}
