/* ==========================================================================
   #INPUTS
   ========================================================================== */

/**
 * Default styling for inputs.
 */

.c-input {
  display: inline-block;
  padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
  border: solid 1px $color-alto;
  font: inherit;
  color: $color-tuna;
  width: 100%;
  outline: none;
  @extend .rounded;
  @include inuit-font-size(14px, 22px);
  padding-top: 7px;
  padding-bottom: 7px;
  &--transparent {
    padding: 0;
    border: none;
    background: transparent;
  }
  &--no-resize {
    resize: none;
  }
  &--rounded {
    @extend .rounded;
  }
  &--payrun-file {
    &::placeholder {
      opacity: 0;
    }
    min-height: 22px;
    &:hover {
      border: 1px solid $color-light-blue;
    }
    &:focus {
      border: 2px solid $ps-color-form-element-focus;
      margin: -1px;
    }
  }
  &--alt {
    border: none;
    background-color: $ps-input-alt;
    padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-small;
    @include inuit-font-size(12px, 20px);
    line-height: 15px;
  }
  &--current-payrun {
    width: 100px !important;
    line-height: 15px;
    height: 24px;
    border: 1px solid $color-grey-tooltip !important;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color-alto;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color-alto;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $color-alto;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color-alto;
  }
  &__value-placeholder {
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $color-tuna;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $color-tuna;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $color-tuna;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $color-tuna;
    }
  }
  &__value-havelock {
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $color-curious;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $color-curious;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $color-curious;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $color-curious;
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
  &--pass {
    padding-right: 30px;
  }
  &--inline {
    width: auto;
  }
  &--overflow-ellipsis {
    text-overflow: ellipsis;
  }
}

textarea {
  &.expand-height {
    overflow: hidden;
    display: block;
  }
}
input,
input:focus {
  box-shadow: none !important;
}

.password__strength {
  position: relative;
  top: 4px;
  width: 100%;
  height: auto;
  display: block;
  background: $color-alabaster;
  transition: width $global-transition;
  padding: 13px 17px 17px 31px;
  border-radius: 4px;
  display: none;

  &.active {
    display: block;
  }

  span {
    display: inline-block;
    padding-bottom: 8px;
    margin-left: -13px;
  }

  ul {
    margin: 0;
    list-style: none;

    li {
      font-size: 10px;
      line-height: 13px;
      margin-bottom: 7px;
      color: $color-lunar;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 0;
        width: 9px;
        height: 9px;
        background-color: $color-silver;
        border-radius: 50%;
        margin-left: -13px;
        transform: translateY(-50%);
      }

      &.fulfilled {
        &::before {
          background-color: $color-curious;
        }
      }
      &.notFulfilled {
        &::before {
          background-color: $color-silver;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

/* ==========================================================================
   #LABELS
   ========================================================================== */

/**
 * Default styling for labels.
 */

.c-label,
label {
  @include inuit-font-size(14px, 24px);
  display: inline;
  font-weight: 500;
}

.c-label {
  &--forgotten-pass {
    padding-top: 14px;
    display: inline-block;
  }
}

/* ==========================================================================
   #SELECTS
   ========================================================================== */

/**
 * Default styling for select component.
 */

.c-select {
  padding: 8px $inuit-global-spacing-unit-small;
  border: solid 1px $color-alto;
  background: $color-white;
  font: inherit;
  color: $color-tuna;
  width: 100%;
  outline: none;
  cursor: pointer;
  @include inuit-font-size(14px, 24px);
  &--transparent {
    padding: 0;
    border: none;
    background: transparent;
  }
  &--small {
    padding: 4px $inuit-global-spacing-unit-tiny;
    @include inuit-font-size(12px, 20px);
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.c-custom-select {
  .Select-control {
    box-shadow: none;
    @extend .rounded;
    border-color: $color-alto;
    @include inuit-font-size(14px, 24px);
    text-align: left;
    height: 38px;
  }
  &.is-focused .Select-control,
  &.is-pseudo-focused .Select-control,
  &.is-open .Select-control {
    outline: none;
  }
  &.is-focused:not(.is-open) > .Select-control {
    box-shadow: none !important;
    border-color: $color-alto !important;
  }
  &.is-open > .Select-control {
    border-color: $color-alto !important;
  }
  .Select-control .Select-value,
  .Select-placeholder {
    color: $color-valhalla;
    line-height: 36px;
    padding-right: $inuit-global-spacing-unit;
  }
  .Select-input {
    input {
      transition: none;
    }
  }
  .Select-menu-outer {
    z-index: 99999;
  }

  .Select-option {
    background-color: $color-white;
    @include inuit-font-size(14px, 24px);
    padding: 2px 10px;
    &:hover,
    &.is-focused {
      background-color: $select-opt-is-focused;
    }
    //&.is-focused,
    &.is-selected {
      background-color: $select-opt-is-selected;
    }
    &.is-disabled {
      cursor: not-allowed;
      color: $color-lunar;
      &:hover,
      &.is-focused {
        background-color: transparent;
      }
    }
  }
  .Select-noresults {
    @include inuit-font-size(14px, 24px);
    padding: 2px 10px;
  }
  .Select-arrow {
    transition: none;
  }
  &--small {
    .Select-control {
      @include inuit-font-size(12px, 20px);
      height: 28px;
    }
    .Select-control .Select-value,
    .Select-placeholder {
      line-height: 28px;
    }
    .Select-input {
      height: 28px;
    }
    .Select-arrow-zone {
      width: 16px;
    }
    .Select-option {
      @include inuit-font-size(12px, 20px);
    }
    .Select-noresults {
      @include inuit-font-size(12px, 20px);
    }
  }
  &--transparent {
    .Select-control {
      background-color: transparent;
      border: none;
      height: 24px;
    }
    .Select-control .Select-value,
    .Select-placeholder {
      line-height: 24px;
      padding-left: 0;
    }
    .Select-input {
      height: 24px;
      padding: 0;
      input {
        padding: 0;
      }
    }
    &.is-disabled > .Select-control {
      background-color: transparent;
      cursor: not-allowed;
    }
    .flag-icon {
      margin-left: 4px;
    }
  }
  &--min-width--small {
    .Select-input {
      min-width: 70px !important;
    }
  }
  &--min-width--normal {
    .Select-input {
      min-width: 100px !important;
    }
  }
  &--min-width--large {
    .Select-input {
      min-width: 160px !important;
    }
  }
  &--min-width--235 {
    .Select-input {
      min-width: 235px !important;
    }
  }
  &--min-width--250 {
    .Select-input {
      min-width: 250px !important;
    }
  }
  &--specific-small {
    .Select-menu-outer {
      margin-top: -28px;
      height: 70px;
    }
    .Select-menu {
      height: 68px;
    }
    .Select-option {
      padding: 2px;
      @include inuit-font-size(12px, 20px);
    }
  }
  &--specific-normal {
    .Select-menu {
      max-height: 110px;
    }
  }
  &--specific-large {
    .Select-menu {
      max-height: 150px;
    }
  }

  .Select-clear-zone {
    &:hover {
      color: $color-cinnabar;
    }
  }

  &.is-frozen {
    position: relative;

    .Select-input {
      position: relative;

      &::before {
        content: attr(aria-label);
        position: absolute;
        top: 25px;
        right: calc(100% - 150px);
        width: 210px;
        height: auto;
        padding: 15px;
        @extend .rounded;
        background-color: $color-mischka;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
        visibility: hidden;
        opacity: 0;
        z-index: 99;
        color: $color-dark-purple;
        font-size: 10px;
        line-height: 12px;
        white-space: normal;
      }
    }

    &:hover {
      .Select-control {
        overflow: visible;

        .Select-input {
          pointer-events: none;
        }
      }
      .Select-input {
        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.option--as-group-title {
  cursor: auto !important;
  padding-left: $inuit-global-spacing-unit-tiny !important;
  font-weight: 700;
}

//Multi select
.Select--multi {
  .Select-control .Select-value {
    color: $ps-select-color;
    border-radius: 2px;
    line-height: 17px;
    background-color: $ps-select-value;
  }
  .Select-control .Select-value-label {
    word-break: break-all;
  }
  &.c-custom-select--transparent {
    .Select-control .Select-value {
      line-height: 14px;
    }
  }
  .Select-value-icon {
    float: right;
    border-right: none;
  }
  .Select-value {
    padding-right: 0 !important;
  }
}

// Report Overrides
.reports-accordion-container,
.view-report-page {
  .c-btn:disabled {
    opacity: 0.4;
  }
  .Select-placeholder {
    padding-left: $inuit-global-spacing-unit-small;
    line-height: 33px;
  }
  .Select--multi {
    .Select-control .Select-value {
      color: $color-white;
      @extend .rounded-sm;
      line-height: 17px;
      background-color: $color-curious;
      font-size: 9px;
    }
    .Select-control .Select-value-label {
      word-break: break-all;
    }
    &.c-custom-select--transparent {
      .Select-control .Select-value {
        line-height: 14px;
      }
    }
    .Select-value-icon {
      float: right;
      border-right: none;
      font-size: 12px;
    }
    .Select-value {
      padding-right: 0 !important;
    }
  }
  .is-disabled {
    &.Select--multi {
      .Select-control .Select-value {
        background-color: $color-extreme-dark-grey;
        .Select-value-label {
          padding-left: $inuit-global-spacing-unit-tiny;
        }
      }
      .Select-control .Select-multi-value-wrapper {
        color: $color-white;
      }
    }
    .Select-arrow-zone {
      visibility: hidden;
    }
  }
}
.custom-select-elipsis > .Select-control .Select-value-label {
  word-break: break-all;
  width: 53px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* ==========================================================================
   #Controls
   ========================================================================== */

/**
 * Default styling for radio buttons and checkboxes.
 */

.control {
  position: relative;
  padding-left: 30px;
  margin-right: $inuit-global-spacing-unit-small;
  @include inuit-font-size(14px, 20px);
  transition: none;
  margin-bottom: 4px;
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    &:checked.control__indicator__input ~ .control__indicator {
      background-size: 12px;
      background-color: $color-curious;
      border-color: $color-curious;
    }
  }
  &__indicator {
    position: absolute;
    top: 50%;
    left: 0;
    height: 17px;
    width: 17px;
    margin-top: -10px;
    border: 1px solid $color-valhalla;
    @extend .rounded;
    background: url('/img/icons/checkbox.svg') no-repeat center center;
    transition:
      border-color 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
    &--center {
      left: 50%;
    }
    &--small {
      height: 15px;
      width: 15px;
      display: inline-block;
    }
    &--in-select {
      background: url('/img/icons/checkbox.svg') no-repeat center center;
      background-size: 10px;
      background-color: $color-curious;
      border-color: $color-curious;
    }
  }
  &--radio .control__indicator {
    border-radius: 50%;
  }
}

/* ==========================================================================
   #Texarea
   ========================================================================== */

/**
 * Default styling for radio buttons and checkboxes.
 */

.c-textarea {
  display: inline-block;
  padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
  border: solid 1px $color-alto;
  font: inherit;
  color: $color-tuna;
  width: 100%;
  outline: none;
  @include inuit-font-size(14px, 24px);
  &--transparent {
    padding: 0;
    border: none;
    background: transparent;
  }
  &--no-resize {
    resize: none;
  }
}

.intl-tel-input {
  display: block;
  * {
    transition: none;
    outline: none;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}
.c-modern-input {
  .c-custom-select--left-padding {
    .Select-control {
      .Select-value-label {
        padding-left: $inuit-global-spacing-unit-small;
      }
    }
  }
  .Select--single {
    .Select-control {
      .Select-value-label {
        line-height: 33px;
      }
    }
  }
  .c-custom-select--overflowy.has-value {
    max-height: 150px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 14px;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #aaaaaa;
    }
    .Select-clear-zone,
    .Select-arrow-zone {
      vertical-align: top;
    }
  }

  .c-custom-select--overflowy.has-value.c-custom-select--employee {
    max-height: none;
    overflow-y: visible;
  }

  .c-custom-select--transparent {
    .Select-control {
      background-color: $color-white;
      min-height: 36px;
      border: 1px solid rgba(41, 21, 71, 0.3);
      @extend .rounded;
      padding-bottom: 4px;
    }
    &.is-disabled > .Select-control {
      border: none;
      background-color: $color-alabaster;
    }
    &.is-disabled > .Select-control .Select-value-label {
      color: white;
    }
  }

  .react-datepicker__input-container {
    background-color: $color-white;
    min-height: 36px;
    border: 1px solid rgba(41, 21, 71, 0.3);
    &--disabled {
      border: none;
      background-color: $color-alabaster;
    }

    @extend .rounded;
    padding-bottom: 4px;
    input {
      line-height: 12px;
      padding-left: $inuit-global-spacing-unit-small;
    }
  }
}

.c-inline-edit-input {
  background-color: $color-light-purple;
  border: 0;
  padding: 0.5rem;
  width: 90%;
  font-family: inherit;
  &:focus-visible {
    outline: none;
  }
}
.c-inline-select-dropdown {
  .Select-control {
    background-color: $color-light-purple;
    @extend .rounded;
  }
}

.c-inline-edit-textarea {
  background-color: $color-light-purple;
  border: 0;
  padding: 0.5rem;
  height: 32px;
  width: 90%;
  font-family: inherit;
  resize: none;
  overflow: hidden;
  &:focus-visible {
    outline: none;
  }
}

#general-ledger-list {
  tbody .Select-control {
    min-width: 200px;
  }
  tbody .Select-menu-outer {
    position: relative;
    top: 0;
  }
}
