.page {
  &--payrun-file {
    &.searching {
      .custom-last-row {
        visibility: hidden;
      }
    }
    .search-results {
      &-container {
        height: 50vh;
      }
      &-text {
        font-size: '22px';
      }
    }
  }
}