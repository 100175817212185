/* ==========================================================================
   #MODAL
   ========================================================================== */

/**
 * Default styling for modal.
 */

.c-modal {
  @extend .rounded;
  /**
   * We add `pre-wrap` to the modals, in order to support new lines via \n.
   * This give us the simplicity to show a simple message string, formatted with new lines,
   * without creating a React message component via JSX explicitly.
   * However, the main reason introducing `white-space` styling,
   * was to add new lines support to api.js `TOKEN_ERROR` message.
   * In that case we can't convert `TOKEN_ERROR` to a React message component easily,
   * because `TOKEN_ERROR` string is used in several features in a comparison statements and have to do a refactoring firstly.
   * For now, it's not a priority to do such API errors refactoring and we will do it using this `white-space` fix.
   */
  white-space:pre-wrap;
  > div {
    @extend .rounded;
    padding: $inuit-global-spacing-unit;
    box-sizing: content-box;
    background: $color-white;
    outline: none;
    box-shadow: 1px 1px 5px $color-lunar;
  }
  @include mq($until: tablet) {
    width: 90% !important;
    height: 90%;
    overflow: auto;
  }
  &--small {
    width: 300px !important;
  }
  &--half {
    width: 50% !important;
    @include mq($until: desktop) {
      width: 90% !important;
    }
  }
  &--big {
    width: 70% !important;
    @include mq($until: desktop) {
      width: 90% !important;
    }
  }
  &--full {
    width: 90% !important;
  }
  &--overflow-y{
    max-height: 90vh;
    overflow-y: auto;
  }
  &--min-height > div {
    min-height: 400px;
  }
  &--min-height--small > div {
    min-height: 300px;
  }
  &--min-height--tiny > div {
    min-height: 200px;
  }
  &--min-height--full > div {
    min-height: 80vh;
  }
  .icon--ex {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  &:focus {
    outline: none;
  }
  thead {
    background: $color-sauve;
  }
  .react-bs-table-pagination {
    margin: 10px 15px 0 15px;
    .row > div {
      padding: 0;
    }
    .row {
      .pagination {
        margin-bottom: 5px;
      }
    }
    .row:after, .row:before {
      height: 6px;
      display: block;
    }
  }
  &.hide-modal-close {
    .icon--ex {
      display: none;
    }
  }
  &-delete-address-confirm {
    .o-grid.o-grid--middle {
      justify-content: center;
    }
    button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  &-delete-bank-account-confirm {
    .o-grid.o-grid--middle {
      justify-content: center;
    }
    button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
