/* ==========================================================================
   #AVATAR
   ========================================================================== */

/**
 * Default styling for avatars.
 */

.c-avatar .sb-avatar__text {
  font-family: $montserrat !important;
  font-weight: 500 !important;
}
