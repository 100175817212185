/* ==========================================================================
   #TABLES
   ========================================================================== */

/**
 * Additional styles for manipulating the structure of HTML `table`s.
 */

.o-table {
  position: relative;
  .grouped-heading__sub {
    background: $color-white;
    color: $color-minsk;
    @include inuit-font-size(12px, 20px);
  }
  thead {
    th {
      color: $ps-th-text-color;
      font-weight: 700;
      @include inuit-font-size(14px, 24px);
      font-family: $montserrat;
      text-align: left;
      &.u-text--minsk {
        color: $color-minsk !important;
      }
      .icon--luggage--white {
        position: absolute;
        top: 50%;
        margin-top: -9px;
        right: 30px;
      }
      .icon--edit {
        position: absolute;
        top: 50%;
        margin-top: -12px;
        right: 0;
      }
    }
  }
  tbody {
    tr {
      td {
        position: relative;
        white-space: pre-line;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
      .o-table__hover-box {
        position: absolute;
        left: 0;
        min-width: 300px;
        z-index: 9;
        display: none;
      }
      &:hover {
        .o-table__hover-box {
          display: block;
        }
        td {
          position: static;
        }
      }
    }
  }
  &--basic {
    background: $color-white;
  }
  &--scrolled--horizontal {
    display: block;
    overflow-x: auto;
  }
  &--striped {
    tr:nth-of-type(odd) {
      background: $color-white;
    }
    tr:nth-of-type(even) {
      background: $color-alabaster;
    }
  }
  &__action {
    position: absolute;
    right: -23px;
    top: -32px;
    cursor: pointer;
    &--tool-bar {
      top: 40px;
    }
    .corner {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }
    .corner--top-right {
      top: -1px;
      right: 0;
      border-width: 0 6px 6px 0;
      border-color: transparent $color-gallery transparent transparent;
    }
    .corner--bottom-right {
      bottom: -1px;
      right: 0;
      border-width: 0 0 6px 6px;
      border-color: transparent transparent $color-gallery transparent;
    }
  }
  &--normal {
    th {
      font-size: 16px !important;
      line-height: 26px !important;
    }
    th,
    td {
      padding: $inuit-global-spacing-unit;
      border-bottom: 1px solid $color-alabaster;
    }
  }
  &--small {
    td {
      @include inuit-font-size(14px, 24px);
    }
  }
  &--tiny {
    td {
      @include inuit-font-size(12px, 24px);
    }
  }
  &--mini {
    th,
    td {
      @include inuit-font-size(12px, 20px);
      padding: $inuit-global-spacing-unit-tiny;
    }
  }
  &--petite {
    td {
      @include inuit-font-size(14px, 24px);
      padding: $inuit-global-spacing-unit-tiny;
    }
  }
  &--border-column {
    td {
      border-bottom: none;
      border-left: 1px solid $color-alabaster;
      &:first-child {
        border: none;
      }
    }
  }
  &__row-expanded {
    border-bottom: 5px solid $color-fountain;
  }
  &--valign-top {
    td {
      vertical-align: top;
      width: 20%;
    }
  }
  &--separated {
    tbody {
      td {
        border-left: 8px solid $color-gallery;
        border-top: 8px solid $color-gallery;
        background: $color-white;
      }
      td:first-child {
        border-left: 0;
      }
    }
  }
  &--fixed-layout__inner {
    overflow-x: scroll;
    overflow-y: visible;
  }
  &--ml-250 {
    width: calc(100% - 350px);
    margin-left: 350px;
  }
  &--fixed-layout {
    table-layout: fixed;
    position: initial;
    th {
      text-align: left;
    }
    .col1 {
      position: absolute;
      left: 0;
      width: 50px;
    }
    .col2 {
      position: absolute;
      left: 50px;
      width: 150px;
    }
    .col3 {
      position: absolute;
      left: 200px;
      width: 150px;
    }
    .fixed-width-200 {
      width: 200px;
    }
    &--120 {
      td,
      th {
        width: 120px;
      }
    }
    &--110 {
      td,
      th {
        width: 110px;
      }
    }
  }
  &--grande {
    &.o-table--fixed-layout {
      th.col1,
      th.col2,
      th.col3 {
        height: 121px;
      }
    }
  }
  &--ta {
    td.col1,
    td.col2,
    td.col3 {
      min-height: 73px;
    }
    .fixed-width--65 {
      width: 65px;
    }
    .fixed-width--80 {
      width: 80px;
    }
    .fixed-width--120 {
      width: 120px;
    }
    .error {
      position: absolute;
      bottom: -6px;
      left: -30px;
      width: 120px;
      font-size: 10px;
      line-height: 10px;
      background: $color-gallery;
      border: 1px solid $color-mandy;
    }
  }
  &--with-small-arrow-box {
    & > tbody > tr:first-of-type > td:last-of-type {
      width: 55px;
      padding-right: 12px;

      span {
        margin: 0 auto;
      }
    }
  }
  .fixed-width--42 {
    width: 42px;
  }
  .fixed-width--50 {
    width: 50px;
  }
  .fixed-width--69 {
    width: 69px;
  }
  .fixed-width--80 {
    width: 80px;
  }
  .fixed-width--91 {
    width: 91px;
  }
  .fixed-width--100 {
    width: 100px;
  }
  .fixed-width--150 {
    width: 150px;
  }
  .fixed-width--172 {
    width: 172px;
  }
  .fixed-width--200 {
    width: 200px;
  }
  .fixed-width--auto {
    width: 100%;
  }
  &--payfile {
    .react-bs-table table > tbody > tr > td:nth-child(1),
    .react-bs-table table > thead > tr:nth-child(1) > th:nth-child(1),
    .react-bs-table table > tfoot > tr:nth-child(1) > th:nth-child(1),
    .react-bs-table table > tbody > tr > td:nth-child(2),
    .react-bs-table table > thead > tr:nth-child(1) > th:nth-child(2),
    .react-bs-table table > tfoot > tr:nth-child(1) > th:nth-child(2),
    .react-bs-table table > tbody > tr > td:nth-child(3),
    .react-bs-table table > tfoot > tr:nth-child(1) > th:nth-child(3),
    .react-bs-table table > thead > tr:nth-child(1) > th:nth-child(3),
    .react-bs-table table > tbody > tr > td:nth-child(4),
    .react-bs-table table > tfoot > tr:nth-child(1) > th:nth-child(4),
    .react-bs-table table > thead > tr:nth-child(1) > th:nth-child(4),
    .react-bs-table table > tbody > tr > td:nth-child(5),
    .react-bs-table table > tfoot > tr:nth-child(1) > th:nth-child(5),
    .react-bs-table table > thead > tr:nth-child(1) > th:nth-child(5) {
      background: inherit;
      position: sticky;
      position: -webkit-sticky;
      z-index: 1;
      box-shadow: 1px 0px 0px $color-white;
    }
    .react-bs-table table > tbody > tr > td:nth-child(1),
    .react-bs-table table > thead > tr:nth-child(1) > th:nth-child(1),
    .react-bs-table table > tfoot > tr:nth-child(1) > th:nth-child(1) {
      left: 0;
    }
    .react-bs-table table > tbody > tr > td:nth-child(2),
    .react-bs-table table > thead > tr:nth-child(1) > th:nth-child(2),
    .react-bs-table table > tfoot > tr:nth-child(1) > th:nth-child(2) {
      left: 80px;
    }
    .react-bs-table table > tbody > tr > td:nth-child(3),
    .react-bs-table table > thead > tr:nth-child(1) > th:nth-child(3),
    .react-bs-table table > tfoot > tr:nth-child(1) > th:nth-child(3) {
      left: 160px;
    }
    .react-bs-table table > tbody > tr > td:nth-child(4),
    .react-bs-table table > thead > tr:nth-child(1) > th:nth-child(4),
    .react-bs-table table > tfoot > tr:nth-child(1) > th:nth-child(4) {
      left: 240px;
    }
    .react-bs-table table > tbody > tr > td:nth-child(5),
    .react-bs-table table > thead > tr:nth-child(1) > th:nth-child(5),
    .react-bs-table table > tfoot > tr:nth-child(1) > th:nth-child(5) {
      left: 390px;
    }
    .react-bs-table table > tbody > tr > td:nth-child(5) {
      box-shadow: 2px 0px 0px $color-minsk;
    }
    .react-bs-table table > tfoot th {
      font-size: 1rem;
    }
    /**
     * Our goal is the table to fill the entire empty height space,
     * without having a page scrollbar.
     * It's fine the scrollbar to be in the table body only.
     * This solution isn't perfect, because in the Payrun pages (--payfile),
     * we have other elements with static heights (not in vh unit).
     * Because of this if we add a new element to the page, then we have to tune-up these `max-height` values,
     * which is error-prone.
     * A better solution would be to refactor the main layout, using relative units (as vh).
     */
    .react-bs-container-body {
      max-height: 50vh;
      overflow-x: hidden;
      @include mq($until: wide) {
        max-height: 40vh;
      }
    }
    .react-bs-container-footer {
      overflow: auto;
    }
    .table-bordered > thead > tr > td,
    .table-bordered > thead > tr > th {
      border: 1px solid #ddd;
    }
  }
  &--fixed-height {
    max-height: 300px;
    overflow-y: auto;
  }
  &--service-list {
    padding-top: 100px;
    margin-top: -100px;
    font-size: 12px;

    thead {
      tr {
        width: 100%;

        th {
          line-height: 15px;
          padding: 17px 11px 17px 5px;
          background-color: $color-sauve;
          color: $ps-color-tb-head;
          font-size: 12px;

          .flag-icon {
            line-height: 17px;
            background-size: cover;
          }

          &:nth-of-type(1) {
            padding-left: 11px;
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1;

            & > div {
              width: 160px;
            }

            span {
              font-size: 16px;
              line-height: 17px;
            }
          }

          &:not(:first-of-type) .service-title {
            position: relative;
            display: block;
            @extend .u-word-break-word;
            span {
              font-size: 16px;
              width: 120px;
              overflow: hidden;
              display: inline-block;
            }
          }
          &:not(:first-of-type) .service-title-word-break-unset {
            @extend .u-word-break-unset;
            span {
              width: 100%;
              min-width: 116px;
            }
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid $color-mischka;
        background-color: $color-white;
        transition: background-color 200ms ease-in-out;
        &:hover {
          td:first-child::before {
            transition: opacity 250ms ease-in-out;
            opacity: 1;
          }
          background-color: #f6f6fb;
        }
        td:first-child::before {
          border: 2px solid $color-dark-purple;
          height: 100%;
          opacity: 0;
          top: 0;
          left: 0;
          @extend .rounded-tr;
          @extend .rounded-br;
          position: absolute;
          transition-delay: 125ms;
          content: ' ';
        }

        td {
          &:nth-of-type(1) {
            padding-left: 11px;
            position: sticky;
            top: 0;
            left: 0;
            background-color: inherit;
            z-index: 1;
          }
        }
      }

      .icon--check-alt {
        min-width: 13px;
        margin: 0 8px 0px 2px;
        padding: 24px 0 0 0;
      }
    }

    &-alt {
      thead,
      tbody {
        display: table;
        width: 100%;

        tr th:first-of-type,
        tr td:first-of-type {
          width: 50%;
        }
      }

      thead tr th:not(:first-of-type) div {
        justify-content: flex-start !important;

        span {
          font-size: 12px !important;
        }
      }

      thead tr th:not(:first-of-type) .service-title span {
        width: 100px;
        @extend .u-word-break-word;
      }
    }
  }
  &--time-attendance {
    td {
      @include inuit-font-size(14px, 24px);
    }
    thead th {
      font-size: 16px;
    }

    &-bottom-bordered-rows {
      tbody {
        tr {
          // See notes about the border in the helper.scss file for
          // why this is what it is
          border-bottom: 0.01px solid $color-mischka;
          background-color: $color-white;
        }
        &:hover tr {
          background-color: $color-generic-hover;
        }
      }
    }
    &-hoverable {
      position: relative;

      td:first-child::before {
        position: absolute;
        content: ' ';
        width: 4px;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        @extend .rounded-tr;
        @extend .rounded-br;
        background-color: $color-dark-purple;
      }

      &:hover {
        background-color: $color-generic-hover;
        td:first-child::before {
          transition: opacity 150ms ease-in-out;
          opacity: 1;
        }
      }
    }

    &-hover-active {
      td:first-child::before {
        position: absolute;
        content: ' ';
        width: 4px;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        opacity: 1;
        @extend .rounded-tr;
        @extend .rounded-br;
        background-color: $color-dark-purple;
      }
    }
  }

  /**
  * fix to mask empty space that appears in header when zooming out on dashboard
  */
  &-dashboard-payroll-header {
    background-color: $color-minsk;
  }
}

/* ==========================================================================
   #PAGINATION
   ========================================================================== */

/**
 * Customizing separate elements from react bootstrap table
 * in order the global look and feel of the system
 */

.o-table-pagination-row--zero-margin {
  .react-bs-table-pagination > .row {
    margin: 0;
    justify-content: flex-end;
  }
}
.c-custom-pagination {
  margin: 0 $inuit-global-spacing-unit-small;
  &__item {
    margin-left: $inuit-global-spacing-unit-small;
    background-color: #f5f4f8;
    color: $color-valhalla;
    @extend .rounded;
    padding: $inuit-global-spacing-unit-tiny;
    min-width: 34px;
    font-size: 12px;
    line-height: 24px;
    display: inline-block;
    text-align: center;
    border: none;
    &:hover {
      background-color: $color-mischka;
    }
    &--active {
      background: $color-curious;
      color: $color-white;
      &:hover {
        background: $color-curious;
      }
    }
  }

  .pagination {
    margin: 0;

    .page-item {
      margin-left: $inuit-global-spacing-unit-small;
      min-width: 33px;
      cursor: pointer;
      .active {
        background-color: $color-curious;
      }
      .page-link {
        text-align: center;
        background-color: #f5f4f8;
        color: $color-valhalla;
        font-size: 12px;
        border: none;
        transition: all 200ms ease-in-out;
        @extend .rounded;
        &:hover {
          background-color: $color-mischka;
        }
      }
    }
  }
}

/* ==========================================================================
   #REACT BOOTSTRAP TABLE
   ========================================================================== */

/**
 * Customizing separate elements from react bootstrap table
 * in order the global look and feel of the system
 */

.react-bs-table-container,
.o-payslip-table-container {
  .form-control {
    border-radius: 0;
    height: 32px;
    width: 50%;
    @include inuit-font-size(14px, 24px);
    box-sizing: border-box;
    &:focus {
      border: 1px solid #ccc;
    }
  }
  .react-bs-table,
  .o-payslip-table {
    &-tool-bar {
      box-sizing: border-box;
    }
    .form-control {
      width: 100%;
    }
    .react-bs-select-all {
      display: none;
    }
    &-bordered {
      border: none;
      border-radius: 0;
    }
    .table-bordered,
    .table-bordered > thead > tr > td,
    .table-bordered > thead > tr > th,
    .table-bordered > tbody > tr > td,
    .table-bordered > tbody > tr > th,
    .table-bordered > tfoot > tr > td,
    .table-bordered > tfoot > tr > th {
      border: none;
    }
    td {
      vertical-align: middle;
      overflow: inherit;
    }
    .react-bs-table__cell--whitespace--normal {
      justify-content: flex-end;
      td div,
      td a,
      th {
        white-space: normal;
        word-wrap: break-word;
      }
    }

    .react-bs-table__cell--overflow-ellipsis {
      td div,
      td a,
      td div a {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }
  &.table-text-full-length .react-bs-table table td,
  &.table-text-full-length .react-bs-table table th,
  &.table-text-full-length .o-payslip-table table td,
  &.table-text-full-length .o-payslip-table table th {
    overflow: auto;
    text-overflow: unset;
    white-space: unset;
  }
  .o-payslip-table-pagination,
  .react-bs-table-pagination {
    > .row {
      justify-content: flex-end;
    }
    .pagination > .active > a,
    .pagination > .active > a:focus,
    .pagination > .active > a:hover,
    .pagination > .active > span,
    .pagination > .active > span:focus,
    .pagination > .active > span:hover {
      background-color: $color-curious;
      border-color: $color-curious;
      color: $color-white;
    }
    .pagination > li > a,
    .pagination > li > span {
      color: $color-curious;
    }
    .dropdown-toggle {
      color: $color-tuna;
      background-color: $color-white;
      border-color: $color-chatelle;
      font-size: $font-size-normal;
      padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
      box-shadow: none !important;
      &:active,
      &:hover {
        color: $color-tuna;
        background-color: $color-alto;
        border-color: $color-chatelle;
      }
      &:focus {
        outline: thin;
      }
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      font-size: $font-size-normal;
      margin-top: $inuit-global-spacing-unit-small;
      &.open.show li a {
        display: block;
        width: 100%;
      }
    }
  }

  .o-table-expandable {
    tbody tr.row-expanded {
      border-bottom: none;

      td {
        padding-bottom: 12px;
      }
    }
    .expanded-section {
      padding: 0;
    }
  }
}

.row-expanded-text {
  padding: 20px 20px 20px 0;
  font-weight: 500 !important;
}

.react-bs-container-body {
  overflow: initial;
}

.react-bs-container-body--overflow-auto {
  .react-bs-container-body {
    overflow: auto;
  }
}

.react-bs-table--reporting,
.react-bs-table--overflow-auto {
  .react-bs-container-body {
    overflow: auto;
    width: 100%;
  }
  .react-bs-container-footer {
    overflow: auto;
  }
  .table-bordered > thead > tr > td,
  .table-bordered > thead > tr > th {
    border: 1px solid #ddd;
  }
  .table-bordered.table-border--none > thead > tr > th {
    border: none !important;
  }
  th {
    vertical-align: bottom;
  }
}

.o-scroll-sync {
  display: flex;
  position: relative;
  //height: 300px;
}

.o-table--react-scroll-sync {
  table {
    border-collapse: collapse;
  }
  td,
  th,
  td > div,
  th > div {
    width: 150px;
  }
  td.col1 {
    width: 50px;
  }
  &.o-table--fixed {
    thead {
      display: block;
      overflow: auto;
    }
  }
}

// Fix select column filter visibility
.o-table-head--overflow {
  .react-bs-container-header,
  .o-payslip-table {
    overflow: visible;
    th {
      overflow: visible !important;
      .c-custom-select {
        overflow: visible;
        font-weight: 500;
        min-width: 120px;
        .Select-control {
          height: 38px;
        }
        .Select-placeholder {
          line-height: 38px;
        }
        .Select-input {
          height: 37px;
        }
      }
    }
  }
}
.o-payslip-table {
  th {
    .c-custom-select {
      font-weight: 500;
      min-width: 100px;
    }
  }
}
.o-table-header--inherit {
  .react-bs-container-header {
    overflow: inherit;
  }
}

@supports (-ms-ime-align: auto) {
  .o-table--react-scroll-sync {
    &.o-table--fixed {
      width: 351px;
      tbody {
        height: 185px;
      }
    }
    &.o-table--dynamic {
      tbody {
        height: 200px;
        width: 100%;
      }
    }
  }
}

// Fix table width on mac webkit browsers
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .o-table--react-scroll-sync {
    &.o-table--fixed {
      width: 351px;
    }
  }
}

.hover-box-cell {
  &:hover .hover-box {
    display: block;
  }
}

.hover-box {
  display: none;
  background-color: $ps-color-info-tooltip;
  color: $ps-color-info-tooltip-text;
  position: absolute;
  z-index: 9;
  min-width: 140px;
  min-height: 40px;
  padding: 8px 10px;
  @extend .rounded-md;
  p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    @include inuit-font-size(10px, 12px);
  }
  &--key-users {
    background-color: $ps-color-base-tooltip;
    color: #fff;
    padding: 13px 15px;
    p {
      justify-content: flex-start;
      line-height: 15px;
    }
  }
  &--pinned-payruns {
    background-color: $ps-opacity-tooltips;
    color: #fff;
    min-width: 50px;
    min-height: unset;
    top: 23px;
    left: -14px;
    @include inuit-font-size(10px, 12px);
  }
  &--variance {
    width: 100px;
    background-color: $ps-variance-hover-color;
    color: #fff;
    padding: 3px 7px;
    margin-top: 23px;
    @include inuit-font-size(8px, 10px);
  }
}

.no-data-padding {
  padding: 6px !important;
}

.table-hint-container {
  height: 24px;
}

.expanded-section > [role='cell'] {
  font-weight: normal;
}
