/* ==========================================================================
   #RESPONSIVE
   ========================================================================== */

/**
 * A series of helper classes to use arbitrarily related to system responsiveness. Only use a helper class if an
 * element/component doesn’t already have a class to which you could apply this
 * styling.
 *
 * A lot of these classes carry `!important` as you will always want them to win
 * out over other selectors.
 */


/**
 * Hide on desktop
 */
.u-hide-on-desktop {
  @include mq($from: desktop) {
    display: none;
  }
}

/**
 * Show on dekstop
 */
.u-show-on-desktop {
  @include mq($until: desktop) {
    display: block !important;
  }
}

/*
* Hide on sizes up until desktop size
*/

.u-hide-on-mobile {
  @include mq($until: desktop) {
    display: none !important;
  }
}

.u-word-break-all {
  word-break: break-all;
}

.u-word-break-word {
  word-break: break-word;
}

.u-word-break-unset {
  word-break: unset;
}