/* ==========================================================================
   #NOTIFICATION
   ========================================================================== */

.c-notification {
  &__indicator {
    position: absolute;
    top: 1px;
    right: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    opacity: 1;
  }
}

.notification-is-read {
  font-weight: normal;
}
