
/* Fix for mac scroll bar performance */
.react-bs-container-footer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.react-bs-container-footer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
