.reports-accordion-container {
  .accordion-expander {
    position: absolute;
    right: 10px;
    top: 20px;
    &.icon--arrow {
      width: 20px;
      background-size: 20px 16px;
    }
  }
}


// -------START:  Company Org Accordion Classes ------ //
.org-accordions {
  .c-custom-pagination {
    margin-right: 1rem;
  }
}
.company-org-accordion-actions {
  right: 1.75rem;
  top: 12px;
  &--hidden {
    display: none;
  }
}
@media (max-width: 740px) {
  .org-accordions {
    .accordion-extender {
      margin-top: -25px
    }
  }
}
// -------END: Company Org Accordion Classes ------ //
.payroll-instance-accordion-header {
  .pin-icon {
    position: relative;
    height: 22px;
    width: 22px;
    transition: all 350ms ease-in-out;
    cursor: pointer;
    &.pinned {
      @extend .icon--pin-icon;
      background-size: 22px !important;
      &:hover {
        @extend .icon--pin-icon-curious;
        background-size: 22px !important;
      }
    }
    &.not-pinned {
      cursor: default;
      .hover-box {
        display: none;
      }
    }
  }
}
