.report-accordion-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  .c-pillbox {
    font-size: 18px;
    font-weight: 500;
    height: 100%;
  }
}

@media only screen and (max-width: 1618px) {
  .report-accordion-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}


@media only screen and (max-width: 1270px) {
  .report-accordion-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}


@media only screen and (max-width: 912px) {
  .report-accordion-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
