/* ==========================================================================
   #PAGE WRAPPER ADMIN
   ========================================================================== */

/**
 * Default styling for page wrapper section.
 */

.o-page-wrapper {
  position: relative;
  height: 100%;
  margin-left: 160px;
  @include mq($until: desktop) {
    height: 100vh;
    background-color: $color-white;
  }
}

/**
 * Default styling for page wrapper section when aside section is collapsed
 */

.o-aside--collapsed {
  .o-page-wrapper {
    margin-left: 65px;
  }
}
