/* ==========================================================================
   #DRAGGABLEBOARD
   ========================================================================== */

/**
 * Custom styling for draggable board.
 */

.c-draggableboard {
  padding: 0 !important;
  background: #fff !important;
  color: $ps-draggable-text-color !important;
  font-family: $montserrat !important;
  font-weight: 500 !important;
  height: auto !important;
  &-background--white {
    background-color: white !important;
  }
  &.react-trello-board > div {
    width: 100%;
    margin-right: 35px;
  }
  .smooth-dnd-container.vertical {
    min-height: 200px;
    padding-bottom: 30px;
  }
  .smooth-dnd-container.horizontal {
    width: 100%;
    section > div {
      min-width: unset;
      align-self: auto;
    }
  }

  .smooth-dnd-container.horizontal > section {
    border-radius: 0;
    margin: 0 0 0 $inuit-global-spacing-unit;
    padding: 0;
    width: 33%;
    background: #fff;
    &:first-child {
      margin: 0;
    }
    > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: $color-sauve;
      border-radius: 5px 5px 0 0;
      padding: $inuit-global-spacing-unit-tiny;
      span {
        font-weight: 600;
        font-size: 15px;
      }
      .icon {
        margin: $inuit-global-spacing-unit-tiny;
      }
    }
    section {
      margin: 0px;
      height: 90px;
    }
  }
  .c-card {
    background-color: white;
    max-width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(41 21 71 / 30%);
    padding: 0;
    margin: 0.25rem 0;
    padding: $inuit-global-spacing-unit-tiny;
    &__info {
      position: absolute;
      top: 8px;
      right: 10px;
      width: 130px;
    }
    &--selected {
      background: $color-sauve;
      border-color: $color-sauve;
    }
    &:hover {
      background-color: $ps-draggable-hover-color;
      border-color: $ps-draggable-hover-color;
    }

    &.is-frozen {
      position: relative;

      &::before {
        content: attr(aria-label);
        position: absolute;
        top: 100%;
        right: -6%;
        transform: translate(-20%, -70%);
        width: 210px;
        height: auto;
        padding: 15px;
        @extend .rounded;
        background-color: $color-mischka;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.35);
        visibility: hidden;
        opacity: 0;
        z-index: 99;
        color: $color-dark-purple;
        font-size: 10px;
        line-height: 12px;
        white-space: normal;
      }

      &:hover {
        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &__options {
    position: absolute;
    bottom: -40px;
    right: 0;
    background: $color-white;
    padding: $inuit-global-spacing-unit-tiny;
    box-shadow: 0 0 3px $color-lunar;
    border-radius: 3px;
    width: max-content;
    z-index: 1;
    li {
      @include inuit-font-size(14px, 24px);
      padding: 0 $inuit-global-spacing-unit-tiny;
      font-weight: 400;
    }
  }
}
