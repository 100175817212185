.c-pillbox {
  border: 0.5px solid $color-dark-grey;
  &--hover-alto:hover {
    cursor: pointer;
    background-color: $color-alto;
  }
  &--hover-sauve:hover {
    cursor: pointer;
    background-color: $ps-pillbox-hover;
  }
}

@media (max-width: 768px) {
  .c-pillbox-container {
    padding-left: 0;
  }
}
