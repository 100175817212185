/* ==========================================================================
   #Important Overrides
   ========================================================================== */

/**
 * This would not be preferrable, but may be required.
 * There are cases where there are outside !important styles
 * which can't be overriden with straight styles
 * so this will be a list of styles we want that need to be forced
 * Use only these as A LAST RESORT
 */

.u-text--center--first-div-important {
  >div {
    text-align: center !important;
  }
}

.w--full--first-div-important {
  >div {
    width: 100% !important
  }
}

.u-display--inline-block-first-div-important {
  >div {
    display: inline-block;
  }
}

.iti-flag {
  width: 20px;
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background: url('/img/flags.png');
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}

.page-link:focus {
  box-shadow: none;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background: url('/img/flags.png');
  }
}

.intl-tel-input .flag-container .arrow.down:after {
  content: \25BC;
}
.intl-tel-input .flag-container .arrow.up:after {
  content: \25B2;
}
.react-datepicker,
.react-datepicker__month-container {
  font-family: "Montserrat",sans-serif;
}
.react-datepicker__close-icon::after {
  content: "\0078";
}

.react-grid-item > .react-resizable-handle {
  background-image: none;

  &::after,
  &::before {
    content: '';
    background-color: $color-valhalla;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &::after {
    width: 2px;
    height: 8px;
    border-top-right-radius: 5px;
  }

  &::before {
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 8px;
    height: 2px;
    border-bottom-left-radius: 5px;
  }
}