.page {
  &--general-ledger {
    #general-ledger-list {
      .error {
        text-align: left !important;
        display: block;
      }
    }
  }
}
