/* ==========================================================================
   #Floaters 
   ========================================================================== */

.gtn-floater-box {
  background-color: $color-valhalla;
  color: #fff;
  max-width: 220px;
  min-width: 140px;
  min-height: 40px;
  padding: 8px 10px;
  @extend .rounded-md;
}

.generic-floater-box {
  background-color: $color-valhalla;
  color: #fff;
  font-weight: normal;
  padding: 8px 10px;
  word-break: break-word;
  @extend .rounded-md;
}

.floater-size {
  &--fit-height {
    width: 140px;
    font-size: 11px;
    height: fit-content;
    text-align: start;
  }

  &--xs {
    width: 150px;
    height: 35px;
  }

  &--s {
    width: 205px;
    height: 90px;
  }
}
