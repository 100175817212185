/* ==========================================================================
   #TABS
   ========================================================================== */

/**
 * Default custom styling for tabs component.
 */

.c-tabs {
  position: relative;

  &__list {
    .slick-list {
      position: relative;
      z-index: 10;
    }

    &.slick-slider {
      background: $color-white;
      margin-top: -24px;
      z-index: 5;
      padding-right: 47px;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 1px;
        background: $color-mischka;
        z-index: 5;
      }
    }
  }

  &__item {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font: inherit;
    margin: 0;
    cursor: pointer;
    border: 0;
    padding: $inuit-global-spacing-unit-small 0;
    font-weight: 600;
    color: $color-valhalla;
    line-height: 17px;
    @include inuit-font-size(16px, 24px);

    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 50%;
      width: 0;
      height: 3px;
      transform: translateX(-50%);
      background: $color-curious;
      z-index: 95;
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s ease-in-out;
    }

    a {
      margin: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit;
      padding: $inuit-global-spacing-unit-small 0;
    }

    &--selected {
      color: $color-curious;
      border-color: currentColor;

      &::after {
        opacity: 1;
        visibility: visible;
        width: 100%;
      }
    }

    &:hover {
      a, span {
        color: $color-curious;
      }

      &::after {
        visibility: visible;
        opacity: 1;
        width: 100%;
        transition: all 0.4s ease-in-out;
      }
    }
  }

  &__panel {
    margin-top: $inuit-global-spacing-unit-large;
    position: relative;
    z-index: auto;
  }
}

.c-modal {
  .c-tabs__list {
    background: transparent;
    position: relative;
    padding: 0;
    margin: 0 0 $inuit-global-spacing-unit;
    border-bottom: 1px solid $color-alto;

    &-border-none {
      border: none
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 1px;
      background: $color-mischka;
      z-index: 5;
    }

    .c-tabs__item {
      padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit;
      position: relative;
      z-index: 10;
      span {
        @include inuit-font-size(16px, 24px);
      }
    }
    .react-tabs__tab--selected {
      position: relative;
      color: $color-curious;
      span {
        font-weight: 600;
      }

      &::after {
        width: 100%;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &-with-top-background {
  .c-tabs__list::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: -24px;
      left: -24px;
      height: 50vh;
      z-index: -1;
    }
  }
}
