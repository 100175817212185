.react-grid-item {
  @extend .rounded;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-bottom: 12px;

  /**
* Scrollbar
*/
  & > div::-webkit-scrollbar {
    width: 20px;
  }

  & > div::-webkit-scrollbar-thumb {
    background-color: $color-pantone;
    border-radius: 98px;
    border: 7px solid $color-white;
  }

}
.dashboard-tile-container {
  @extend .rounded-md;
  margin: 0 1rem 1rem 1rem;
  max-height: 100%;
  overflow: auto;
  &--no-border {
    border: none;
  }
  &.no-items {
    position: absolute;
    width: 95%;
    top: 40%;
  }

  tbody tr {
    color: $color-valhalla;
  }
}

// The following 2 overrides fix an issue with the library where the buttons on the dashboard are not clickable, but instead the whole item triggers dragging
// ! Do not remove unless sure the dashboard buttons work with an updated version of react-grid-layout

.react-grid-item.react-grid-placeholder {
  pointer-events: none;
}
.react-grid-item {
  z-index: 3;
}
