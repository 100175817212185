/* ==========================================================================
   #PAGE CONTENT
   ========================================================================== */

/**
 * Default styling for page content section.
 */

.c-content-wrapper {
  position: relative;
  background: $color-white;
  padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
}