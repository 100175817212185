
// Timings
.ease-in-out-250 { transition: all 250ms ease-in-out;}
.ease-in-out-max-height-250 { transition: max-height 250ms ease-in-out;}
// Transforms
.rotate-180 { transform: rotate(180deg); }

.max-height-to-open { max-height: unset; }

// Specific Component Animations
.accordion-body {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  &.expanded {
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
  }
  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #AAAAAA;
  }
  .Select.c-custom-select.is-open {
    .Select-menu-outer {
      position: initial;
   }
  }
}

.hover {
  &--shadow-md {
    transition: all 200ms ease-in-out;
    &:hover {
      @extend .shadow-md;
    }
  }
}

// ZeroTOuch icon and rotation animation
.zt {
  position: relative;
  background-image: url(/img/zt.png);
  background-size: initial;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 150px;

// TODO Martin It will be better to have this arrows as svg so we can animate the rotation instead using gif file. The designer should provide us this svg untill then we will use the gif.
  // &::before {
  //   position: absolute;
  //   content: '';
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url(/img/icons/zt-arrows.svg);
  //   background-size: 119px 119px;
  //   background-repeat: no-repeat;
  //   background-position: center center;
  // }

  &-animation {
    background-image: url(/img/zt-animation.gif);
    background-size: contain;
    height: 160px;
    &::before {
      // animation: rotation 2s infinite linear;
      display: none;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}