/* ==========================================================================
   #STATUS-LABEL
   ========================================================================== */

/**
 * Same styling as a button but does not function as a button
 */

.c-status-label {
  display: inline-block;
  vertical-align: middle;
  @extend .rounded;
  &--rounded {
    @extend .rounded
  }
  font: inherit;
  text-align: center;
  margin: 0;
  border: 0;
  padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit;
  @include inuit-font-size(14px, 24px);
  background-color: $ps-color-secondary;
  color: $color-valhalla;
}

/* Size variants
   ========================================================================== */

.c-status-label--tiny {
  padding: 3px 10px;
}

.c-status-label--tiniest {
  padding: 1px 5px;
}

.c-status-label--small {
  padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
}

.c-status-label--large {
  padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
}

.c-status-label--long {
  padding-left: 32px;
  padding-right: 32px;
}

/* Style variants
   ========================================================================== */

.c-status-label--warning {
  background-color: #FFE7EB;
}

.c-status-label--caution {
  background-color: #FFE2B6;
}

.c-status-label--safe {
  background-color: #D9F0CC;
}

.c-status-label--neutral {
  background-color: #EDEAF2;
}




