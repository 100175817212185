/* ==========================================================================
   #ICONS
   ========================================================================== */

/**
 * Default styling for icons.
 */

.icon--back {
  width: 22px;
  height: 22px;
  margin-left: -8px;
  padding: 11px;
  background: url('/img/icons/chevron-symbol.svg') no-repeat center center;
}

.icon--check {
  width: 15px;
  height: 15px;
  padding: 10px;
  background: url('/img/icons/check-icon.svg') no-repeat center center;
  background-size: 13px 13px;
  display: block;
  border: 1px solid $color-tuna;
  border-radius: 50%;
  margin: 0 auto;
  &--left-side {
    margin: 0;
  }
}

.svg--component {
  display: flex;
  align-items: center;
  padding: 0 8px 0 8px;
}

.icon--pause {
  width: 22px;
  height: 22px;
  padding: 10px;
  background: url('/img/icons/pause-icon.svg') no-repeat center center;
  background-size: 22px 22px;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
  &--left-side {
    margin: 0;
  }
}

.icon--ex {
  width: 12px;
  height: 12px;
  background: url('/img/icons/ex-icon.svg') no-repeat center center;
  background-size: 10px 10px;
  display: inline-block;
  margin: 0 auto;
  cursor: pointer;
  &--bordered {
    display: block;
    padding: 10px;
    border: 1px solid $color-tuna;
    border-radius: 50%;
    cursor: default;
  }
  &--left-side {
    margin: 0;
  }
}

.icon--arrow {
  display: block;
  width: 15px;
  height: 8px;
  background: url('/img/icons/arrow-icon-down.svg') no-repeat center center;
  background-size: 15px 8px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  &.active {
    transform: rotate(180deg);
    transition: all 200ms ease-in-out;
  }
  &.left {
    transform: rotate(90deg);
  }
  &.right {
    transform: rotate(270deg);
  }
  &--white {
    background: url('/img/icons/arrow-icon-down-white.svg') no-repeat center center;
  }
  &--hover-right:hover {
    display: block;
    background: url('/img/icons/arrow-icon-down-curious.svg') no-repeat center center;
    transform: rotate(270deg) translateY(3px);
    transition: all 200ms ease-in-out;
    background-size: 15px 8px;
  }
  &--hover-left:hover {
    display: block;
    background: url('/img/icons/arrow-icon-down-curious.svg') no-repeat center center;
    transform: rotate(90deg) translateY(3px);
    transition: all 200ms ease-in-out;
    background-size: 15px 8px;
  }
}

.icon-sort-arrow {
  display: block;
  width: 8px;
  height: 6px;
  background: url('/img/icons/arrow-icon-sort.svg') no-repeat center center;
  background-size: 10px 6px;
  cursor: pointer;
  &.up {
    transform: rotate(180deg);
  }
  &:hover {
    background: url('/img/icons/arrow-icon-sort-curious.svg') no-repeat center center;
    background-size: 10px 6px;
  }
}

.icon-sort-arrow-up-down {
  display: block;
  width: 8px;
  height: 12px;
  background: url('/img/icons/arrow-icon-sort-both.svg') no-repeat center center;
  background-size: 10px 12px;
  &:hover {
    background: url('/img/icons/arrow-icon-sort-both-curious.svg') no-repeat center center;
    background-size: 10px 12px;
  }
}

.icon--assign {
  width: 22px;
  height: 18px;
  background: url('/img/icons/assign-icon.svg') no-repeat center center;
  background-size: 20px;
  display: block;
}

.icon--edit {
  width: 24px;
  height: 24px;
  background: url('/img/icons/edit-icon.svg') no-repeat center center;
  background-size: 20px;
  display: block;
  &--minsk {
    background: url('/img/icons/edit-icon-minsk.svg') no-repeat center center;
  }
}

.icon--view {
  width: 24px;
  height: 24px;
  background: url('/img/icons/search-icon.svg') no-repeat center center;
  background-size: 20px;
  display: block;
}

.icon--preview {
  width: 24px;
  height: 24px;
  background: url('/img/icons/preview-icon.svg') no-repeat center center;
  background-size: 20px;
  display: block;
}

.icon--person-arrow {
  width: 24px;
  height: 24px;
  background: url('/img/icons/person-arrow.svg') no-repeat center center;
  background-size: 20px;
  display: block;
}

.icon--person-question {
  width: 24px;
  height: 24px;
  background: url('/img/icons/person-question.svg') no-repeat center center;
  background-size: 20px;
  display: block;
}

.icon--person-remove {
  width: 24px;
  height: 24px;
  background: url('/img/icons/person-remove.svg') no-repeat center center;
  background-size: 20px;
  display: block;
}

.icon--save {
  width: 24px;
  height: 24px;
  background: url('/img/icons/save.svg') no-repeat center center;
  background-size: 20px;
  display: block;
}
.icon--bar-chart {
  width: 24px;
  height: 24px;
  background: url('/img/icons/bar-chart.svg') no-repeat center center;
  background-size: 20px;
  display: block;
}

.icon--filter {
  &:hover {
    cursor: pointer;
  }
  width: 1.25rem;
  height: 1.25rem;
  background: url('/img/icons/filter.svg') no-repeat center center;
  background-size: 13px;
  display: block;
  &:hover {
    background: url('/img/icons/filter-hover.svg') no-repeat center center;
    background-size: 13px;
    transition: all 200ms ease-in-out;
  }
}

.icon--hamburger-filter-icon {
  &:hover {
    cursor: pointer;
  }
  width: 24px;
  height: 24px;
  background: url('/img/icons/hamburger-filter-icon.svg') no-repeat center center;
  display: block;
  @extend .ease-in-out-250;
  &.filter-opened {
    @extend .rotate-180;
  }
}

.icon--chart-sort-icon {
  &:hover {
    cursor: pointer;
  }
  width: 24px;
  height: 24px;
  background: url('/img/icons/chart-sort-icon.svg') no-repeat center center;
  background-size: 24px;
  display: block;
  @extend .ease-in-out-250;
  &.sort-opened {
    @extend .rotate-180;
    @extend .ease-in-out-250;
  }
}

.icon--plus {
  width: 24px;
  height: 24px;
  background: url('/img/icons/plus-icon.svg') no-repeat center center;
  background-size: 20px;
  display: block;
}

.icon--trash {
  width: 24px;
  height: 24px;
  background: url('/img/icons/trash-icon.svg') no-repeat center center;
  background-size: 20px;
  display: block;
}

.icon--upload {
  width: 24px;
  height: 24px;
  background: url('/img/icons/upload-icon.svg') no-repeat center center;
  background-size: 20px;
  display: block;
}

.icon--download {
  width: 24px;
  height: 24px;
  background: url('/img/icons/download-icon.svg') no-repeat center center;
  background-size: 20px;
  display: block;
}

.icon--people--curious {
  width: 50px;
  height: 15px;
  padding: 15px;
  background: url('/img/icons/people-icon-curious.svg') no-repeat center center;
  background-size: 20px;
}

.icon--users {
  width: 22px;
  height: 22px;
  padding: 10px;
  background: url('/img/icons/users.svg') no-repeat center center;
  background-size: 22px;
}

.icon--locked {
  width: 30px;
  height: 24px;
  background: url('/img/icons/locked-icon.svg') no-repeat center center;
  background-size: 20px;
  display: inline-block;
}

.icon--unlocked {
  width: 30px;
  height: 28px;
  background: url('/img/icons/unlocked-icon.svg') no-repeat center center;
  background-size: 28px;
  display: inline-block;
}

.icon--doc {
  width: 20px;
  height: 20px;
  background: url('/img/icons/doc-icon.svg') no-repeat center center;
  background-size: 15px;
  display: inline-block;
  cursor: pointer;
}

.icon--history {
  width: 24px;
  height: 24px;
  background: url('/img/icons/history-icon.svg') no-repeat center center;
  background-size: 20px;
  display: block;
}

.icon--history--blue {
  width: 30px;
  height: 16px;
  background: url('/img/icons/history-icon-blue.svg') no-repeat center center;
  background-size: 16px;
  display: inline-block;
  cursor: pointer;
}
.icon--history--supernova {
  width: 30px;
  height: 16px;
  background: url('/img/icons/history-icon-supernova.svg') no-repeat center center;
  background-size: 16px;
  display: inline-block;
  cursor: pointer;
}

.icon--share {
  width: 24px;
  height: 24px;
  background: url('/img/icons/share-icon.svg') no-repeat center center;
  background-size: 20px;
  display: block;
}

.icon--luggage {
  width: 15px;
  height: 18px;
  background: url('/img/icons/luggage-icon.svg') no-repeat center center;
  background-size: 15px 18px;
  display: inline-block;
  &--white {
    background: url('/img/icons/luggage-icon-white.svg') no-repeat center center;
  }
}

.icon--calendar-holiday {
  width: 15px;
  height: 18px;
  background: url('/img/icons/calendar-holiday-icon.svg') no-repeat center center;
  background-size: 10px;
  display: inline-block;
}

.icon--calendar-year {
  width: 24px;
  height: 24px;
  background: url('/img/icons/icon-calendar-year.svg') no-repeat center center;
  display: block;
}

.icon--not-started {
  width: 28px;
  height: 28px;
  background-size: 28px;
  display: block;
}

.icon--in-progress {
  width: 28px;
  height: 28px;
  background-size: 28px;
  display: block;
}

.icon--completed {
  width: 28px;
  height: 28px;
  background-size: 28px;
  display: block;
}

.icon--dots {
  width: 28px;
  height: 28px;
  background: url('/img/icons/dots-icon.svg') no-repeat center center;
  background-size: 28px;
  display: block;
}

.icon--payroll {
  width: 28px;
  height: 28px;
  background: url('/img/icons/payroll-icon-minsk.svg') no-repeat center center;
  background-size: 28px;
  display: block;
  margin: auto;
}

.icon--document {
  width: 28px;
  height: 28px;
  background: url('/img/icons/file-icon-minsk.svg') no-repeat center center;
  display: block;
  margin: auto;
}

.icon--note {
  width: 27px;
  height: 28px;
  background: url('/img/icons/notes-icon-minsk.svg') no-repeat center center;
  display: block;
  margin: auto;
}

.icon--visible {
  width: 24px;
  height: 24px;
  background: url('/img/icons/visible-icon.svg') no-repeat center center;
  background-size: 24px;
  display: block;
  position: absolute;
  top: 30px;
  right: $inuit-global-spacing-unit-tiny;
}

.icon--not-visible {
  width: 24px;
  height: 24px;
  background: url('/img/icons/not-visible-icon.svg') no-repeat center center;
  background-size: 24px;
  display: block;
  position: absolute;
  top: 30px;
  right: $inuit-global-spacing-unit-tiny;
}

.icon--notes--blue {
  width: 17px;
  height: 17px;
  background: url('/img/icons/notes-icon.svg') no-repeat center center;
  background-size: 16px;
  display: inline-block;
  cursor: pointer;
}

.icon--reversion--green {
  width: 18px;
  height: 16px;
  background: url('/img/icons/reversion-icon-green.svg') no-repeat center center;
  background-size: 14px;
  display: inline-block;
  cursor: pointer;
}

.icon--reversion--red {
  width: 18px;
  height: 16px;
  background: url('/img/icons/reversion-icon-red.svg') no-repeat center center;
  background-size: 14px;
  display: inline-block;
  cursor: pointer;
}

.icon--stats {
  width: 40px;
  height: 48px;
  background: url('/img/icons/login-icon-stats.svg') no-repeat center center;
  display: inline-block;
}

.icon--execution {
  width: 52px;
  height: 50px;
  background: url('/img/icons/login-icon-execution.svg') no-repeat center center;
  display: inline-block;
}

.icon--scale {
  width: 50px;
  height: 50px;
  background: url('/img/icons/login-icon-scale.svg') no-repeat center center;
  display: inline-block;
}

.icon--reimport-arrows {
  width: 20px;
  height: 25px;
  background: url('/img/icons/reimport-btn-arrows.svg') no-repeat center center;
  display: block;
  background-size: 14px;
}

.icon--hr-report-avatar-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/hr-report-avatar-logo.svg') no-repeat center center;
  display: inline-block;
}

.icon--finance-report-avatar-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/finance-report-avatar-logo.svg') no-repeat center center;
  display: inline-block;
}

.icon--payroll-report-avatar-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/payroll-report-avatar-logo.svg') no-repeat center center;
  display: inline-block;
}

.icon--control-governance-report-avatar-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/control-governance-report-avatar-logo.svg') no-repeat center center;
  display: inline-block;
}

.icon--report-workforce-headcount-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/report-workforce-headcount-logo.svg') no-repeat center center;
  display: inline-block;
}

.icon--report-joiners-headcount-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/report-joiners-headcount-logo.svg') no-repeat center center;
  display: inline-block;
}

.icon--report-leavers-headcount-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/report-leavers-headcount-logo.svg') no-repeat center center;
  display: inline-block;
}

.icon--report-total-costs-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/report-total-costs-logo.svg') no-repeat center center;
  background-size: 83px;
  display: inline-block;
}

.icon--report-er-contribution-costs-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/report-er-contribution-costs-logo.svg') no-repeat center center;
  background-size: 83px;
  display: inline-block;
}

.icon--report-total-pay-elements-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/report-total-pay-elements-logo.svg') no-repeat center center;
  background-size: 83px;
  display: inline-block;
}

.icon--report-total-ee-deductions-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/report-total-ee-deductions-logo.svg') no-repeat center center;
  background-size: 83px;
  display: inline-block;
}

.icon--report-total-ee-net-deductions-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/report-total-ee-net-deductions-logo.svg') no-repeat center center;
  background-size: 83px;
  display: inline-block;
}

.icon--internal-report-avatar-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/internal-report-avatar-logo.svg') no-repeat center center;
  display: inline-block;
}

.icon--report-disclaimer-warning {
  width: 15px;
  height: 15px;
  background: url('/img/icons/report-analytics-disclaimer-warning.svg') no-repeat center center;
  display: inline-block;
}

.icon--variance-value-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/variance-value-logo.svg') no-repeat center center;
  background-size: 83px;
  display: inline-block;
}

.icon--variance-percentage-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/variance-percentage-logo.svg') no-repeat center center;
  background-size: 83px;
  display: inline-block;
}

.icon--variance-elements-logo {
  width: 100%;
  height: 100%;
  background: url('/img/icons/variance-elements-logo.svg') no-repeat center center;
  background-size: 83px;
  display: inline-block;
}

.icon-view,
.icon-padlock-locked,
.icon-padlock-unlocked {
  &:hover {
    transition: $global-transition;
    fill: $color-cobalt;
  }
}

.icon--swap-icon {
  width: 24px;
  height: 24px;
  background: url('/img/icons/swap-icon.svg') no-repeat center center;
  background-size: 20px;
  display: block;
  &-gray {
    background: url('/img/icons/swap-icon-gray.svg') no-repeat center center;
  }
}

.icon--pin-icon {
  width: 24px;
  height: 24px;
  background: url('/img/icons/pin-icon.svg') no-repeat center center;
  background-size: 20px;
  display: block;
  &-curious {
    background: url('/img/icons/pin-icon-curious.svg') no-repeat center center;
  }
}
