.floatingDialog {
  @extend .rounded;
  box-sizing: content-box;
  background: $color-white;
  outline: none;
  box-shadow: 1px 1px 5px $color-lunar;

  &--overlay {
    background: rgba(0, 0, 0, 0.8);
    z-index: 10000005;
  }
  &--centered-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Display Center */
  &--dc {
    display: grid;
    place-items: center;
  }

  /* Banner Styles */
  &--as-a-banner {
    width: fit-content;
    height: 600px;
    overflow-x: hidden;
    border-radius: 0px 0px 20px 20px !important;
  }
  &--as-a-mobile-banner {
    width: fit-content;
    height: 100vh;
    overflow-x: hidden;
    border-radius: 20px 0px 0px 20px !important;
    margin-left: 28px;
    overflow-y: auto;
  }
  &--banner-header {
    margin-top: 56px;
    font-size: 20px;
    line-height: 24.38px;
    text-align: left;
    width: 100%;
  }

  /* Banner center */
  &--bc {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  a,
  p,
  section,
  div,
  span {
    outline: none !important;
    box-shadow: none !important;
  }
}
