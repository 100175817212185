/* ==========================================================================
   #BADGES
   ========================================================================== */

/**
 * Default styling for icons.
 */

.c-badge {
  text-align: left;
  min-width: 160px;
  padding: 0 $inuit-global-spacing-unit-small;
  display: inline-block;
}
