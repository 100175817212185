///* ========================================================================
//   #COLORS
//   ======================================================================== */
// The colors settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Palette
$ps-color-primary: #4e317b;

$ps-color-secondary: #edeaf2;
$ps-color-secondary-100: #d8d2e0;

$ps-color-accent: #ffcf04;
$ps-color-dark-gray: #3b3b3d;
$ps-color-medium-gray: #b3b3b3;
$ps-color-light-gray: #f8f8fd;

// Standardise some colors.
$color-white: #fff;
$color-alabaster: #f3f3f3;
$color-gallery: #ebebeb;
$color-mid-grey: #efeeee;
$color-dark-grey: #c4c4c4;
$color-extreme-dark-grey: #a4a4a4;
$color-alto: #d8d8d8;
$color-tuna: #3b3b3d;
$color-lunar: #454644;
$color-supernova: $ps-color-accent;
$color-drover: #fceca5;
$color-minsk: $ps-color-primary;
$color-minsk-hover: #3d2463;
$color-curious: #5292dd;
$color-curious-hover: #2268b4;
$color-seagull: #6fb9e3;
$color-fountain: #4dbab8;
$color-mandy: #ec5967;
$color-mandys-pink: #f4bac1;
$color-black: #000;
$color-cinnabar: #ea3c34;
$color-mantis: #8cc658;
$color-chatelle: #b9adca;
$color-jumbo: #78787a;
$color-placebo: #e6e6e6;
$color-mid-blue: #52a9dd;
$color-pink: #e293a7;
$color-light-green: #88c8b4;
$color-blue: #6d98de;
$color-orange: #f6ab66;
$color-havelock: #52a9dd;
$color-silver: #c4c4c4;
$color-light-purple: $ps-color-secondary;
$color-mischka: #dbd6e2;
$color-valhalla: #291547;
$color-medium-purple: $ps-color-secondary-100;
$color-dark-purple: #020202;
$color-darker-purple: #1f172b;
$color-holiday-purple: #7b6f8d;
$color-pantone: #6e647d;
$color-whisper: #f5f3f8;
$color-sauve: $ps-color-secondary;
$color-lilac: #f8f8fd;
$color-cobalt: #0052b4;
$color-chelsea: #7ab059;
$color-hosta: #dedfe8;
$color-alpine: #eff0f7;
$color-white-gray: #f5f4f8;
$color-light-blue: #8484b1;
$color-generic-hover: #f6f6fb;
$color-grey-tooltip: #8b809b;
$color-pale: #aca6b6;
$color-highlighter: #f6cf4e;

// Components Colors
$ps-color-tr-delimiter: #291547;
$ps-pillbox-hover: $color-sauve;
$ps-calendar-event-content: $color-sauve;
$select-opt-is-selected: $color-mischka;
$select-opt-is-focused: $color-white-gray;
$ps-regular-hover: $color-cobalt;
$ps-input-alt: $color-white-gray;
$ps-select-value: none;
$ps-select-color: $color-curious;
$ps-opacity-tooltips: $color-grey-tooltip;
$ps-color-tb-head: $color-valhalla;
$ps-calendar-current-border-day: $color-cobalt;
$ps-color-tb-subheader: $ps-color-secondary-100;
$ps-color-form-element-focus: $color-cobalt;
$ps-color-base-tooltip: $color-grey-tooltip;
$ps-color-info-tooltip: $color-mischka;
$ps-color-info-tooltip-text: $color-darker-purple;
$ps-draggable-text-color: $color-tuna;
$ps-calendar-year-color: $color-tuna;
$ps-draggable-hover-color: $color-sauve;
$ps-th-text-color: $color-valhalla;
$ps-variance-hover-color: $ps-color-medium-gray;
$ps-calendar-day-header: $ps-color-secondary;
$ps-calendar-day-text: $color-valhalla;
$ps-heading-subbranding-color: $color-valhalla;
$ps-generic-colort-text: $color-tuna;
$ps-time-attendece: $color-chatelle;
$ps-color-tb-selected: #b9adca;
$ps-color-dual-selector-selected: #f5f3f8;

:root {
  --ps-color-dark-magnifier-search: #291547;
  --ps-color-magnifier-search: #a39baf;
  --ps-color-medium-magnifier-search: #e0dce5;
  --ps-color-light-magnifier-search: #f5f4f8;
  --ps-trello-icon-color: #291547;

  --ps-switch-toggle-on: #5292dd;
  --ps-color-alternative: #5292dd;
  --ps-color-alternative-light: #bddbff;
  --ps-color-alternative-dark: #0052b4;

  --ps-muilti-arrow-color: #5d516a;
  --ps-muilti-arrow-moved-color: #968aa3;

  --ps-padlock-top: #bddbff;
  --ps-padlock-left: #5292dd;
  --ps-padlock-right: #0052b4;
  --ps-padunlock-top: #dbd6e2;
  --ps-padunlock-left: #6e647d;
  --ps-padunlock-right: #6e647d;
  --ps-color-floater-fill: #291547;
  --ps-dager-alert-color: #eb3528;
}

// filters
$ps-filter-primary: brightness(0) saturate(100%) invert(20%) sepia(22%) saturate(2777%) hue-rotate(232deg) brightness(99%) contrast(94%);
