/* ==========================================================================
   #CALENDAR
   ========================================================================== */

/**
 * Custom styling for icons.
 */

.c-calendar {
  &__holder {
    height: calc(100vh - 190px);
    * {
      transition: none;
    }
    &--border {
      .rbc-time-header > .rbc-row:nth-child(2) {
        border-bottom: 4px solid $color-alto;
        border-right: 4px solid $color-alto;
      }
    }
  }
  &__datepicker {
    position: absolute;
    top: -35px;
    width: 100%;
    opacity: 0;
  }
  &__filters {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__weekNumber {
    position: absolute;
    left: -30px;
    color: $color-lunar;
    opacity: 0.5;
  }
}

.rbc {
  //Header
  &-calendar {
    color: $color-valhalla;
  }
  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $ps-calendar-day-header;
    border-bottom: 1px solid $color-hosta !important;
    border-top: 1px solid $color-hosta;
    border-radius: 10px 10px 0 0;
    font-weight: 600;
    text-transform: uppercase;
    height: 41px;
    color: $ps-calendar-day-text;
    @include inuit-font-size(12px, 24px);
  }
  &-time-header-cell-single-day {
    display: flex;
  }
  &-time-header {
    &-content {
      border: none;
    }
  }
  &-month-view {
    border: 1px solid $color-hosta;
    border-top: none;
    border-radius: 10px;
    .rbc-day-bg {
      border: none;
      border-left: 1px solid $color-hosta;
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  &-time-view {
    border: none;
  }
  &-time-view .rbc-header {
    display: block;
    border: none !important;
    padding: 0 -1px 16px 0;
  }
  &-time-header > .rbc-row:first-child {
    border: none;
  }
  &-time-header > .rbc-row:last-child {
    margin-bottom: $inuit-global-spacing-unit;
  }
  &-time-header > .rbc-row > * + * {
    border: none;
    padding: 0;
  }
  &-month-row + .rbc-month-row {
    border-top: 1px solid $color-hosta;
  }
  //Day
  &-day-bg {
    position: relative;
    background: $color-white;
    border-bottom: 1px solid $color-hosta;
    border-left: 1px solid $color-hosta;
    &:first-child {
      border-radius: 0 0 0 10px;
    }
    &:last-child {
      border-right: 1px solid $color-hosta;
      border-radius: 0 0 10px 0;
    }
    &:only-child {
      border-radius: 0 0 10px 10px;
    }
    .icon--calendar-holiday {
      position: absolute;
      top: 4px;
      right: $inuit-global-spacing-unit-small;
    }
    .u-bg-current-day {
      border: 1px solid $ps-calendar-current-border-day;
      height: 100%;
      display: block;
    }
  }
  &-date-cell {
    text-align: left;
    line-height: 22px;
  }
  &-date-cell a {
    @include inuit-font-size(10px, 18px);
  }
  &-show-more {
    @include inuit-font-size(10px, 18px);
    color: $color-tuna;
    font-weight: 600;
    margin: 0 $inuit-global-spacing-unit-tiny 0 $inuit-global-spacing-unit-small;
    text-align: center;
  }
  //Event
  &-event {
    padding: 0;
    margin: 0 $inuit-global-spacing-unit-tiny 3px $inuit-global-spacing-unit-small;
    background: transparent;
    color: $color-valhalla;
    &-content {
      @include inuit-font-size(12px, 18px);
      &-custom {
        padding-left: 20px;
        padding-right: 20px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: $ps-calendar-event-content;
      }
      &-daily {
        width: 29%;
        padding-left: 27px;
        padding-right: 40px;
        @include mq($until: desktop) {
          width: 100%;
        }
        .event-name {
          @include inuit-font-size(14px, 24px);
        }
        .flag-icon {
          width: 18px !important;
          height: 18px;
          margin-top: -9px !important;
        }
        .owner {
          width: 35px !important;
          @include inuit-font-size(8px, 24px);
        }
      }
      .flag-icon {
        position: absolute;
        top: 50%;
        margin-top: -6px;
        left: 4px;
      }
      .isReverted {
        position: absolute;
        top: 0;
        right: 30px;
      }
      .owner {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        text-align: center;
        color: white;
        background: $color-minsk;
        padding: 0px 2px;
        width: 25px;
        font-size: 0.625rem;
      }
    }
    &.rbc-selected {
      background: transparent;
    }
  }
  //Time Content
  &-time-content {
    display: none;
  }
  &-label {
    display: none;
  }
  //Popup
  &-overlay {
    margin-top: 22px;
    z-index: 999;
    margin-left: 8px;
    min-width: 13.6% !important;
    &-header {
      display: none;
    }
    .rbc-event {
      margin: 0 0 4px;
    }
  }
  &-month-view > .rbc-month-row:last-child {
    .rbc-row-bg > .rbc-day-bg:first-child {
      border-radius: 0 0 0 10px;
    }
    .rbc-row-bg > .rbc-day-bg:last-child {
      border-radius: 0 0 10px 0px;
    }
  }
  &-row-bg,
  &-month-row {
    overflow: visible;
  }
}

.rbc-time-view {
  .rbc-header {
    background: transparent;
    padding: 0 0 16px 0;
  }
  .rbc-today .weekly-header,
  .rbc-today .daily-header {
    border-radius: 10px 10px 0 0;
  }
  .weekly-header {
    width: 100%;
    height: 33px;
    border: 1px solid $color-hosta;
    border-radius: 10px 10px 0 0;
    border-right: none;
    &:last-child {
      border-right: 1px solid $color-hosta;
    }
  }
  .daily-header {
    width: 30.14%;
    height: 34px;
    @include mq($until: desktop) {
      width: 100%;
    }
  }
  .rbc-row-content {
    .rbc-row:first-child {
      padding-top: 4px;
    }
  }
  .rbc-abs-full,
  .rbc-row-bg {
    overflow: visible;
  }
  .rbc-day-bg {
    .icon--calendar-holiday {
      position: absolute;
      top: -28px;
      right: 3px;
    }
    &:only-child {
      max-width: 30% !important;
      @include mq($until: desktop) {
        max-width: 100% !important;
      }
      .u-bg-current-day {
        border: none;
      }
    }
  }
}

// Migration Styles
.rbc-calendar {
  .rbc-event {
    width: auto;
    color: $color-valhalla;
  }
  .rbc-date-cell a {
    padding-left: $inuit-global-spacing-unit-small;
    font-weight: 600;
  }
  .rbc-row-content {
    height: 100%;
    .rbc-row {
      .rbc-date-cell:nth-child(1) {
        a {
          padding-right: 7px;
        }
      }
      .rbc-date-cell:nth-child(2) {
        a {
          padding-right: 5px;
        }
      }
      .rbc-date-cell:nth-child(3) {
        a {
          padding-right: 4px;
        }
      }
      .rbc-date-cell:nth-child(4) {
        a {
          padding-right: 4px;
        }
      }
      .rbc-date-cell:nth-child(5) {
        a {
          padding-right: 2px;
        }
      }
      .rbc-date-cell:nth-child(6) {
        a {
          padding-right: 2px;
        }
      }
      .rbc-date-cell:nth-child(7) {
        a {
          padding-right: 1px;
        }
      }
    }
  }
}
.dashboard-tile-container {
  .rbc-calendar {
    .rbc-time-header-content {
      border-left: 4px solid $color-alto;
      border-right: 4px solid $color-alto;
      border-bottom: 4px solid $color-alto;
    }
  }
}

// Style overrides for the dashboard calendar daily view

.dashboard-calendar {
  .rbc-day-bg.rbc-today {
    border: 1px solid $color-cobalt;

    &:only-child {
      max-width: 100% !important;
    }
  }
  .rbc-header.rbc-today {
    &:only-child {
      background-color: #eff0f7;
    }
  }
}

.calendar-yearly {
  margin-left: 50px;
  margin-right: 10px;
  color: #291547;
  &-flex {
    display: flex;
    justify-content: space-between;
  }
  &-month {
    flex: 1 0 25%;
    padding: 5px;
    border-right: 1px solid #e0dce5;
    border-bottom: 1px solid #e0dce5;
    @media (max-width: 1012px) {
      &:nth-of-type(3n) {
        border-right: none;
      }
      &:nth-of-type(10n),
      &:nth-of-type(11n),
      &:nth-of-type(12n) {
        border-bottom: none;
      }
    }
    @media (min-width: 1013px) {
      &:nth-of-type(4n) {
        border-right: none;
      }
      &:nth-of-type(9n),
      &:nth-of-type(10n),
      &:nth-of-type(11n),
      &:nth-of-type(12n) {
        border-bottom: none;
      }
    }
    &--name {
      text-align: center;
      font-weight: 700;
      font-size: 1em;
      margin-bottom: 14px;
    }
  }
  &-day {
    flex: 1 0 14%;
    font-size: 0.75rem;
    text-align: center;
    position: relative;
    margin: 1px;
  }
  &-date {
    width: 29px;
    flex: 1 0 14%;
    padding: 5px;
    margin: 1px;
    position: relative;
    color: #291547;
    text-align: center;
    font-weight: 400;
    border-radius: 50px;
    border: none;
    outline: none;
    .circle-border {
      display: none;
    }
    &--inactive-month {
      color: #ccc8d2;
      border-radius: 50px;
      border: none;
      outline: none;
    }
  }
  &-divider {
    margin-left: 50px;
    margin-right: 10px;
    margin-bottom: 30px;
    border: 0;
    height: 5px;
    background: #e0dce5;
  }
  &-legend {
    &-dot {
      height: 25px;
      width: 25px;
      background-color: $color-holiday-purple;
      border: 2px solid $color-holiday-purple;
      border-radius: 50%;
      display: inline-block;
      margin-right: 14px;
      &--inputs {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 2px solid $color-orange;
        margin-right: 14px;
      }
      &--gtn {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 2px solid $color-pink;
        margin-right: 14px;
      }
      &--paydate {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 2px solid $color-light-green;
        margin-right: 14px;
      }
    }
  }
  &-holiday-list {
    display: grid;
    grid-auto-flow: column;
  }
  &-holiday-date {
    width: 55px;
    color: $ps-regular-hover;
    font-weight: 700;
  }
  &-holiday-calendar-date {
    width: 25px;
    height: 25px;
    color: $color-white-gray;
    background-color: $color-holiday-purple;
    border-radius: 50%;
    display: inline-block;
    &:hover .hover-box {
      display: block;
    }
  }
  &-special-date {
    width: 29px;
    display: inline-block;
    position: relative;
    .circle-border {
      display: inline-block;
      position: absolute;
      top: -3px;
      left: -1px;
      width: 30px;
      height: 30px;
      border-width: 3px;
      border-style: solid;
      border-radius: 50%;
      transform: rotate(45deg);
    }
    &:hover .hover-box {
      display: block;
    }
  }
  &-inputs-calendar-date .circle-border {
    border-color: $color-orange;
  }
  &-payday-calendar-date .circle-border {
    border-color: $color-light-green;
  }
  &-gtn-calendar-date .circle-border {
    border-color: $color-pink;
  }
  &-inputspayday-calendar-date .circle-border {
    border-color: $color-orange $color-orange $color-light-green $color-light-green;
  }
  &-inputsgtn-calendar-date .circle-border {
    border-color: $color-orange $color-orange $color-pink $color-pink;
  }
  &-paydaygtn-calendar-date .circle-border {
    border-color: $color-light-green $color-light-green $color-pink $color-pink;
  }
  &-all-calendar-date .circle-border {
    border-top: 3px solid $color-light-green;
    border-right: 3px solid $color-pink;
    border-bottom: 3px solid $color-pink;
    border-left: 3px solid $color-light-green;
    transform: rotate(15deg);
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: -2px;
      top: -3px;
      width: 29px;
      height: 29px;
      border-radius: 50%;
    }
    &:before {
      border-top: 3px solid $color-orange;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 3px solid transparent;
      transform: rotate(60deg);
    }
    &:after {
      border-top: 3px solid $color-orange;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 3px solid transparent;
      transform: rotate(30deg);
    }
  }
  &-date-hover-box {
    background-color: $ps-color-info-tooltip;
    color: $ps-calendar-day-text;
    padding: 13px 15px;
    margin-top: 28px;
    margin-left: -7px;
    p {
      justify-content: flex-start;
      line-height: 15px;
    }
  }
  &-scrollable {
    position: static;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 75vh;
    @media (max-height: 1200px) {
      max-height: 65vh;
    }
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
  &-exporting .calendar-yearly-scrollable {
    overflow: hidden;
    max-height: unset;
  }
  .year-select {
    .Select-control {
      border: none;
      @include inuit-font-size(20px, 30px);
      height: 30px;
      margin-top: -4px;
      &:hover {
        box-shadow: none;
        .Select-value-label {
          color: $ps-regular-hover !important;
        }
      }
      .Select-value-label {
        color: $color-curious !important;
        font-weight: 700;
      }
    }
    .Select-option {
      background-color: $color-white;
      @include inuit-font-size(14px, 24px);
      padding: 2px 10px;
      &:hover,
      &.is-focused {
        background-color: $select-opt-is-focused;
      }
      &.is-selected {
        background-color: $select-opt-is-selected;
      }
      &.is-disabled {
        cursor: not-allowed;
        color: $color-lunar;
        &:hover,
        &.is-focused {
          background-color: transparent;
        }
      }
    }
    .Select-menu-outer {
      @extend .rounded;
      border-top: 1px solid $color-alto;
    }
  }
}

.calendar-year-color {
  color: $ps-calendar-year-color;
}
