/* ==========================================================================
   #WRAPPERS ADMIN
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper--full {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  background: $color-white;
}