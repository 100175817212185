.page {
  &--recent-reports-dashboard-view {
    .page {
      &--dashboard-tile-header {
        .c-btn--tiny {
           margin-right: 0.650rem !important;
        }
      }
    }
  }

  &--dashboard-tile-header {
    .c-btn--tiny .icon--preview {
      background-size: 19px;
    }
  }
  &--recent-reports-list-item {
    .c-btn--tiny .icon--preview {
      background-size: 19px;
    }
  }
  &--payroll-dashboard-view {
    .o-payslip-table thead tr {
      background-color: #dddddd;
    }
    .o-payslip-table-container .o-payslip-table table tbody tr td .pin-icon.pinned,
    .o-payslip-table-container .o-payslip-table table tbody tr td .pin-icon.not-pinned {
      margin-right: 0.5rem;
      margin-left: 0;
      cursor: default;
      .hover-box {
        display: none;
      }
    }
    .o-payslip-table-container .o-payslip-table table tbody tr td .pin-icon.pinned:hover {
      @extend .icon--pin-icon;
    }
  }
}
