#mobile-employee-header-view {
  display: none;
}
@media only screen and (max-width: 1280px) {
  #mobile-employee-header-view {
    display: block;
  }
  #employee-self-service {
    .employee-header-avatar,
    .employee-header-details,
    .c-nav-bar-profile--details,
    .c-nav-bar-profile,
    .employee-inner-wrapper-container .tabs-container .c-slider {
      display: none;
    }
    .employee-inner-wrapper-container .c-tabs__panel {
      margin-top: 0;
    }

    section.o-page-wrapper {
      margin-left: 0;
    }

    .c-content-wrapper {
      padding: 24px;
      max-height: calc(100vh - 72px);
      overflow-y: auto;
    }
    .c-header {
      min-height: 72px;
    }

    #desktop-employee-header-view {
      display: none;
    }
    #hamburger-menu {
      position: fixed;
      top: 10px;
      right: 25px;
      z-index: 9990;
      margin: 10px;
      transition: opacity 0.75s cubic-bezier(0.77, 0.2, 0.05, 1);
    }
    #close-hamburger-menu {
      opacity: 0;
      position: fixed;
      top: 10px;
      right: 25px;
      z-index: 9990;
      margin: 10px;
      transform-origin: 0% 0%;
      transform: translate(10%, 0);
      transition: transform 0.55s cubic-bezier(0.77, 0.2, 0.05, 1);
    }

    #close-hamburger-menu span,
    #hamburger-menu span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 6px;
      position: relative;
      background: black;
      z-index: 1;
      transform-origin: 4px 0px;
      transition:
        transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        opacity 0.55s ease;
    }
    #close-hamburger-menu span {
      background-color: $color-white;
    }
  }
}

@media only screen and (max-width: 740px) {
  .o-wrapper--full {
    .c-header {
      padding-right: 20px;
    }
  }

  #employee-self-service {
    section.o-page-wrapper {
      .c-header {
        padding: 12px;
        .o-layout.o-layout--middle {
          div:nth-child(2) {
            margin-top: -25px;
          }
        }
      }
    }
  }

  .c-header {
    .company-header-details {
      align-items: flex-start;
    }
  }
}

#mobile-side-menu {
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  margin: -100px 0 0 0;
  padding: 0;
  padding-top: 125px;
  // max-height: 0;
  background: #3b3b3d;
  list-style-type: none;
  opacity: 0;
  -webkit-font-smoothing: antialiased;
  transition: opacity 0.2s ease-out;
  z-index: 0;
  li a.active {
    color: $color-supernova;
  }
  .c-logo {
    display: block;
  }
  .o-list-bare {
    .nav-label:hover {
      cursor: pointer;
    }
  }
}
.mobile-payslip-logo {
  left: 10px;
}
.mobile-payslip-logo,
.mobile-side-menu-header span {
  position: absolute;
  top: 120px;
}
.mobile-side-menu-header span {
  margin-left: -30px;
}
#mobile-side-menu li {
  padding: 1.5rem 0;
  color: $color-white;
  font-size: 22px;
  a {
    text-align: center;
  }
}

.mobile-navbar-logo {
  position: absolute;
  left: 10px;
  top: 20px;
  &--center {
    top: 15px;
    .mobile-image--sizer {
      img {
        margin: 0 auto;
        display: block;
        max-width: 100px;
        max-height: 50px;
        height: auto;
        width: auto;
      }
    }
  }
}

/*
 * And let's slide it in from the left
 */
.mobile-menu--opened {
  #mobile-side-menu {
    // transform: none;
    opacity: 1;
    z-index: 999;
    // max-height: unset;
  }
  #hamburger-menu {
    opacity: 0;
    transition: none;
  }
  #close-hamburger-menu {
    transform: none;
    opacity: 1 !important;
    span {
      background-color: $color-white;
    }
    span:first-child {
      transform: rotate(45deg) translate(-2px, -1px);
    }
    span:nth-child(2) {
      transform: rotate(-45deg) translate(-7px, 0px);
    }
  }
}

.mobile-rows {
  background-color: $color-white;
  margin-bottom: 1rem;
  @include inuit-font-size(14px, 24px);
  div {
    border-bottom: 1px solid $color-mischka;
  }
  span {
    min-height: 1.45rem;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
  span:nth-child(2) {
    max-width: 49%;
    text-align: right;
  }
}

// Input fields look until screen is desktop size
.c-input--mobile {
  @include mq($until: desktop) {
    line-height: 2rem;
    border-radius: 0.25rem;
  }
}

.c-input--mobile-height {
  @include mq($until: desktop) {
    line-height: 2rem;
  }
}

.c-label--mobile {
  @include mq($until: desktop) {
    color: $ps-color-dark-gray;
  }
}

.c-btn--mobile {
  @include mq($until: desktop) {
    border-radius: 0.25rem;
  }
}

.mobile-no-data-message--centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  color: $color-tuna;
  font-size: 1.25em;
}

.c-modal {
  @include mq($until: desktop) {
    z-index: 9991 !important;
  }
}

.mobile-accordion-title {
  h2 {
    word-break: break-word;
    width: 70%;
  }
}
