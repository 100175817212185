/* ==========================================================================
   #FLAGS
   ========================================================================== */

/**
 * Additional styles for manipulating the flags components.
 * https://github.com/lipis/flag-icon-css
 */

.flag-icon {
  &--circle {
    border-radius: 50%;
  }
  &--shadow {
    @extend .shadow-md;
  }
  &--huge {
    width: 118px !important;
    height: 118px !important;
  }
  &--large {
    width: 80px !important;
    height: 80px;
  }
  &--normal {
    width: 60px !important;
    height: 60px;
  }
  &--medium {
    width: 35px !important;
    height: 35px;
  }
  &--small {
    width: 30px !important;
    height: 30px;
  }
  &--tiny {
    width: 22px !important;
    height: 22px;
  }
  &--extra-tiny {
    width: 18px !important;
    height: 18px;
  }
  &--miniature {
    width: 12px !important;
    height: 12px;
  }
}
