/* ==========================================================================
   #SCROLLBAR
   ========================================================================== */

/**
 * Custom styling for scrollbar.
 */
 
.c-custom-scrollbar {
  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: $color-pantone;
  }
}