.Toastify {
  &__toast-container {
    width: 360px;
  }
  &__toast-body {
    padding-right: 15px;
    padding-top: 10px;
  }
  &__toast-container--top-right {
    top: 4.5rem;
    z-index: 99999;
  }
  .position-absolute.u-absolute--top-right {
    right: 10px;
    top: 5px;
  }
  .sk-circle {
    margin: 10px auto;
  }
}
