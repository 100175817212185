.page {
  &--cct-success-list-view {
    .failed-to-delete-items {
      thead {
        visibility: hidden;
        tr {
          th {
            min-height: 20px;
            padding-top: 0;
            padding-bottom: 0;
            max-height: 20px;
          }
        }
      }
      tbody {
        border-top: .03rem solid $color-mischka;
        tr {
          background-color: #f5f4f8 !important;
        }
      }
    }
  }
}