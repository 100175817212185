.c-display-cards {
  &-count {
    font-size: 50px;
    font-weight: 700;
    line-height: 61px;
    color: $color-valhalla;
  }


  &-subtitle {
    font-size: 22px;
    font-weight: 400;
    color: $color-valhalla;
  }

  &--subtitle-fit {
    font-size: 16px;
    font-weight: 400;
    color: $color-valhalla;
    white-space: break-spaces;
  }

  &-amount {
    font-size: 28px;
    font-weight: 700;
    color: $color-valhalla;
    @include mq($until: 1610px) {
      font-size: 22px;
    }
  }

  &-variance {
    .c-display-cards-subtitle {
      max-width: 142px;
      @include mq($until: 1400px) {
        max-width: unset;
      }
    }

    @include mq($until: 1400px) {
      flex-grow: 1;
      margin-right: $inuit-global-spacing-unit-small;
    }
  }
}

.c-percentage-cards .c-display-cards-count {
  font-size: 40px;
}
