.page {
  &--pay-taxes-create {
    .Select-menu-outer {
      position: relative
    }
  }
  &--pay-taxes-edit {
    .Select-menu-outer {
      position: relative
    }

    .o-payslip-table tbody td.text-center input.c-input {
      text-align: center;
    }
  }
  &--swap-pay-taxes-elements-container {
    .sk-circle {
      margin: 10px auto;
      width: 20px;
      height: 20px;
    }
    .animate-opacity {
      opacity: 0;
      transition: all 350ms ease-in-out;
      &.show {
        opacity: 1;
      }
    }

  }
}
