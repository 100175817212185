.page {
  &--employee-pay-taxes-form-fields {
    .disabled-rounded-input-border input {
      border-radius: 0;
    }
  }
  &--personal-view-container,
  &--employee-job-org-container {
    .o-payslip-table {
      table {
        table-layout: auto;
      }
      tbody td:nth-child(2) {
        @extend .u-2\/3;
        padding-left: 14px;
      }
      tbody td:nth-child(3) {
        @extend .u-1\/3;
      }
      tbody td div {
        word-break: inherit;
      }
      .intl-tel-input.allow-dropdown input[type='tel'] {
        background-color: $color-light-purple;
      }
    }
  }
  &--pay-taxes-view-container {
    &-layout {
      width: 100%;
      @include mq($from: 1180px) {
        width: 50%;
      }
    }
    .o-payslip-table-container .o-payslip-table table tbody tr {
      .c-inline-edit-input.rounded {
        &::-webkit-input-placeholder {
          text-align: center;
        }
      }
    }
  }
  &--pay-taxes-deductions,
  &--pay-taxes-fixed-pay-elements {
    .o-payslip-table-container .o-payslip-table table tbody tr {
      .c-inline-edit-input.rounded.text-right {
        &::-webkit-input-placeholder {
          text-align: center;
        }
      }
      &.row-expanded {
        border-bottom: 0;
        div {
          font-weight: 500 !important;
        }
      }
    }
    .expanded-section {
      padding: 0;
      background-color: $color-lilac;
      table tbody tr {
        background-color: $color-lilac;
      }
    }
    .expanded-section table tbody tr td:first-child::before {
      // display: none;
      // width: 0 !important;
      opacity: 0 !important;
    }
  }
}

@media (max-width: 1680px) {
  .page {
    &--personal-view-container,
    &--employee-job-org-container {
      .o-payslip-table {
        tbody td:nth-child(2) {
          width: 50% !important;
        }
        tbody td:nth-child(3) {
          width: 50% !important;
        }
        tbody td div {
          word-break: break-all;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .page {
    &--personal-view-container,
    &--employee-job-org-container {
      .o-payslip-table {
        tbody td div {
          word-break: break-all;
        }
      }
    }
  }
}
